import { AutocompleteRequestArgs, fetchAutocompleteResults } from 'app/providers';
import {
  optionsSelector,
  distribusionEnabledSelector,
  showStationSuggestionSelector,
} from 'app/store/selectors';
import { retry, select, race, call, delay, put } from 'redux-saga/effects';
import { reportAutocompleteError } from 'app/store/actions/location';
import { SearchMaskDirection } from 'app/models/types/store/location';

export function* fetchRequestTimeout(
  requestArgs: AutocompleteRequestArgs,
  direction: SearchMaskDirection,
) {
  try {
    // start a race between the searchResultsRequest and a timeout and see what wins
    const winner: Record<string, never> = yield race({
      autocompleteRequest: call(fetchAutocompleteResults, requestArgs),
      timeout: delay(5000),
    });

    // request successful
    if (winner.autocompleteRequest) {
      return winner.autocompleteRequest;
    }

    // request timed out
    yield put(reportAutocompleteError('error.autocomplete', 'Request timed out.', direction));
    throw new Error('timeout');
  } catch (error: any) {
    yield put(reportAutocompleteError('error.autocomplete', 'Failed to fetch results.', direction));
    throw new Error(error);
  }
}

export function* requestAutocomplete({
  query,
  departureCity,
  direction,
}: {
  query: string;
  departureCity: string | null;
  direction: SearchMaskDirection;
}): any {
  const { autocompleteUrl, locale, partner } = yield select(optionsSelector);
  const distribusionEnabled: boolean = yield select(distribusionEnabledSelector);
  const delayBetweenRetries = 1000;
  const showStationSuggestions: boolean = yield select(showStationSuggestionSelector);
  const requestArgs: AutocompleteRequestArgs = {
    autocompleteUrl,
    query: encodeURIComponent(query),
    locale,
    flixbusCitiesOnly: !distribusionEnabled,
    departureCity,
    partner,
    showStationSuggestions,
  };
  return yield retry(3, delayBetweenRetries, fetchRequestTimeout, requestArgs, direction);
}
