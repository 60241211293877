import { fromUnixTime, isBefore, startOfDay } from 'date-fns';
import { CityDetailsResult } from 'app/models/types/store/location';

export const isSearchInvalid = ({
  passengerCount = null,
  departureDate = null,
  wheelchairsCount = null,
  departureCity,
  arrivalCity,
}: {
  passengerCount: null | number;
  departureDate?: null | number;
  wheelchairsCount?: null | number;
  departureCity?: CityDetailsResult;
  arrivalCity?: CityDetailsResult;
}) => {
  if (
    (departureCity && !departureCity.uuid && !departureCity.legacyId) ||
    (arrivalCity && !arrivalCity.uuid && !arrivalCity.legacyId)
  ) {
    return true;
  }

  if (wheelchairsCount !== null && (passengerCount === null || wheelchairsCount > passengerCount)) {
    return true;
  }

  if (passengerCount !== null && passengerCount < 1) {
    return true;
  }

  if (departureDate !== null) {
    return isBefore(startOfDay(fromUnixTime(departureDate)), startOfDay(new Date()));
  }

  return false;
};
