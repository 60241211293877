import { getUnixTime } from 'date-fns';

import {
  SET_DEPARTURE_DATE,
  SET_RETURN_DATE,
  SHOW_RETURN_DATE,
  HIDE_RETURN_DATE,
  DateActions,
} from 'app/store/actions';
import { StoreDateField } from 'app/models/types/store/date';

const date = new Date();
const defaultState: StoreDateField = {
  departureDate: getUnixTime(date),
  returnDate: getUnixTime(date),
  returnDateVisible: false,
  preSelectReturnMonth: false,
};

export default (state = defaultState, action: DateActions) => {
  switch (action.type) {
    case SET_DEPARTURE_DATE:
      return {
        ...state,
        departureDate: action.date,
        returnDate: !state.returnDateVisible
          ? action.date
          : Math.max(action.date, state.returnDate),
        preSelectReturnMonth: false,
      };
    case SET_RETURN_DATE:
      return {
        ...state,
        returnDate: Math.max(action.date, state.departureDate),
        preSelectReturnMonth: true,
      };
    case SHOW_RETURN_DATE:
      return {
        ...state,
        returnDateVisible: true,
      };
    case HIDE_RETURN_DATE:
      return {
        ...state,
        returnDateVisible: false,
        returnDate: state.departureDate,
      };
    default:
      return state;
  }
};
