import { getEventEmitter } from '@flixbus/transclusion-eventing';
import { RequestSearchPayload } from 'app/models/types/store/general';

export const EVENT_REQUEST_SEARCH = 'search.search-mask.request-search';

export const emitRequestSearchEvent = (payload: RequestSearchPayload) => {
  const eventEmitter = getEventEmitter();

  eventEmitter.emit(EVENT_REQUEST_SEARCH, payload);
};
