import { getIsGlobalTripSearch } from '@webc/meeseeks-ui-sdk';
import { RequestSearchPayload } from 'app/models/types/store/general';
import { CityDetailsResult, PartialSearchMaskLocation } from 'app/models/types/store/location';

export const getPayloadForSearchResultsRedirect = ({
  departureCity,
  arrivalCity,
  locale,
  routeName,
  selectedProducts,
  departureDate,
  returnDate,
}: {
  departureCity: CityDetailsResult | PartialSearchMaskLocation;
  arrivalCity: CityDetailsResult | PartialSearchMaskLocation;
  locale: string;
  routeName: string;
  selectedProducts: Record<string, any>;
  departureDate: number;
  returnDate: number | null;
}) => {
  const payload: RequestSearchPayload = {
    departureCity: departureCity.uuid || '',
    arrivalCity: arrivalCity.uuid || '',
    routeName,
    departureDate,
    returnDate,
    products: selectedProducts,
    locale,
    arrivalStop: arrivalCity.stationId,
    departureStop: departureCity.stationId,
    arrivalStopName: arrivalCity.stationName,
    departureStopName: departureCity.stationName,
    departureCountryCode: departureCity.countryCode,
    arrivalCountryCode: arrivalCity.countryCode,
  };

  if (
    getIsGlobalTripSearch({
      locale,
      departureCountryCode: departureCity.countryCode,
      arrivalCountryCode: arrivalCity.countryCode,
    })
  ) {
    payload.isGlobalTripSearch = 1;
  }

  const normalizedPayload = {
    ...payload,
    products: Object.values(payload.products).reduce((acc, curr) => {
      acc[curr.id] = curr.selectedAmount;
      return acc;
    }, {}),
    departureStation: null, // stations are not longer used, but cant break external API yet
    arrivalStation: null,
  };

  return normalizedPayload;
};
