import { getEventEmitter } from '@flixbus/transclusion-eventing';

export type selectedStationEventType = {
  isStationClicked: boolean;
  numberOfAvailableStationsInCity: number;
  isTrainStation: boolean;
};

export const EVENT_SELECT_STATION = 'search.search-mask.auto_suggest_click';

export const emitSelectStationEvent = ({
  isStationClicked,
  numberOfAvailableStationsInCity,
  isTrainStation,
}: selectedStationEventType) => {
  const getEventLabel = () => {
    if (isStationClicked) {
      return 'station_clicked';
    }

    if (numberOfAvailableStationsInCity > 1) {
      return 'multi_station_city_clicked';
    }

    return 'single_station_city_clicked';
  };
  getEventEmitter().emit(EVENT_SELECT_STATION, { [getEventLabel()]: true, isTrainStation });
};
