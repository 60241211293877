import { StoreState } from 'app/models/types';
import { fromUnixTime } from 'date-fns';
import { createSelector } from '@reduxjs/toolkit';

export const departureDateTimestampSelector = (state: StoreState) => state.date.departureDate;
export const returnDateTimestampSelector = (state: StoreState) => state.date.returnDate;
export const returnDateVisibleSelector = (state: StoreState) => state.date.returnDateVisible;
export const preSelectReturnMonthSelector = (state: StoreState) => state.date.preSelectReturnMonth;

export const selectedReturnDateSelector = createSelector(
  returnDateTimestampSelector,
  returnDateVisibleSelector,
  (returnDate, returnDateVisible) => (returnDateVisible === true ? returnDate : null),
);

export const departureDateSelector = createSelector(departureDateTimestampSelector, (date) =>
  fromUnixTime(date),
);

export const returnDateSelector = createSelector(returnDateTimestampSelector, fromUnixTime);
