import { call, select, takeEvery } from 'redux-saga/effects';
import { HideReturnDateAction, HIDE_RETURN_DATE } from 'app/store/actions';
import {
  cmsApiOnReturnDateChangeSelector,
  dateLabelSelector,
  returnDateTimestampSelector,
} from 'app/store/selectors';

import { fromUnixTime } from 'date-fns';

export function* onHideReturnDateWorker() {
  const returnDateTimeStamp: ReturnType<typeof returnDateTimestampSelector> = yield select(
    returnDateTimestampSelector,
  );
  const onReturnDateChanged: ReturnType<typeof cmsApiOnReturnDateChangeSelector> = yield select(
    cmsApiOnReturnDateChangeSelector,
  );
  const dateLabel: ReturnType<typeof dateLabelSelector> = yield select(dateLabelSelector);

  const dateLocalized = dateLabel(fromUnixTime(returnDateTimeStamp));
  const payload = {
    date: returnDateTimeStamp,
    dateLocalized,
  };

  yield call(onReturnDateChanged, payload);
}

export function* onHideReturnDate() {
  yield takeEvery<HideReturnDateAction>(HIDE_RETURN_DATE, onHideReturnDateWorker);
}
