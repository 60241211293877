import React from 'react';
import { breakpointMatches } from 'app/functions';
import { useTranslations } from 'app/hooks/useTranslations';
import { tabTrap } from 'app/providers/tabTrap';
import { Link } from '@flixbus/honeycomb-react';

export const DiscountInfo = ({
  isLast,
  showExtendedProductsInfo,
  handleClose,
  extendedProductsInformationLink,
  wrapperRef,
}: {
  isLast?: boolean;
  showExtendedProductsInfo: boolean;
  handleClose: () => void;
  extendedProductsInformationLink: string;
  wrapperRef: React.RefObject<HTMLDivElement>;
}) => {
  const isSmallBreakpoint = breakpointMatches('small').matches;
  const { getTranslationDiscountsInfo } = useTranslations({
    getTranslationDiscountsInfo: 'search_bundle.search_widget.passengers.popup.link.social_info',
  });

  if (!showExtendedProductsInfo) return null;

  const tabTrapAction = (event: React.KeyboardEvent) =>
    tabTrap({
      event,
      firstItemSelector: '#productSummary',
      lastItemSelector: `#discountInfo`,
      isActive: !!isLast,
      wrapper: wrapperRef.current,
      callback: handleClose,
    });

  return (
    <Link
      href={extendedProductsInformationLink}
      id="discountInfo"
      target="_blank"
      rel="noopener noreferrer"
      onKeyDown={isSmallBreakpoint ? undefined : tabTrapAction}
    >
      {getTranslationDiscountsInfo()}
    </Link>
  );
};
