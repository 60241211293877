import { StoreTranslationsDateField } from 'app/models/types/store/translations';

export const REQUEST_TRANSLATIONS_SUCCEEDED = 'REQUEST_TRANSLATIONS_SUCCEEDED';
export type RequestTranslationsSucceededAction = {
  type: typeof REQUEST_TRANSLATIONS_SUCCEEDED;
  strings: { [key: string]: string };
  dates: StoreTranslationsDateField;
};
export const requestTranslationsSucceeded = (
  strings: { [key: string]: string },
  dates: StoreTranslationsDateField,
) => ({
  type: REQUEST_TRANSLATIONS_SUCCEEDED,
  strings,
  dates,
});

export type TranslationActions = RequestTranslationsSucceededAction;
