import { emitFieldClosedEvent } from 'app/events';
import { MutableRefObject } from 'react';

export const closeElement = (
  element: string,
  options?: { [key: string]: any },
  triggerElement?: MutableRefObject<HTMLInputElement | null>,
) => {
  setTimeout(() => {
    const active = document.activeElement;

    if (
      (!document.getElementById('search-mask-component')?.contains(active) ||
        !active?.hasAttribute('aria-expanded') ||
        (active?.hasAttribute('aria-expanded') &&
          active?.getAttribute('aria-expanded') === 'false')) &&
      !(triggerElement && triggerElement?.current?.getAttribute('aria-expanded') === 'true')
    ) {
      emitFieldClosedEvent(element, options);
    }
  }, 100);
};
