import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'app/hooks/useTranslations';
import styles from './Autocomplete.scss';
import { removeRecentCity } from 'app/store/actions';
import { Icon, IconClose } from 'app/icons';
import { Button } from '@flixbus/honeycomb-react';

export const RemoveButton: FC<{
  cityId: string;
  cityName: string;
  direction: 'from' | 'to';
}> = ({ cityId, direction, cityName }) => {
  const dispatch = useDispatch();
  const { getRemoveLabel } = useTranslations({
    getRemoveLabel: 'search_mask.label.remove',
  });

  return (
    <Button
      aria-label={`${getRemoveLabel()}: ${cityName}`}
      display="square"
      extraClasses={styles.removeButton}
      onClick={() => {
        dispatch(removeRecentCity(direction, cityId));
      }}
      onKeyDown={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <Icon appearance="primary" InlineIcon={IconClose} extraClasses={styles.resetLeft} />
    </Button>
  );
};
