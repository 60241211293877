import { ProductsId } from '@webc/meeseeks-ui-sdk';
import { ProductDataType } from 'app/models/types/store/product';

export const SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY';
export type SetProductQuantityAction = {
  type: typeof SET_PRODUCT_QUANTITY;
  productType: ProductsId;
  quantity: number;
};
export const setProductQuantity = (
  productType: ProductsId,
  quantity: number,
): SetProductQuantityAction => ({
  type: SET_PRODUCT_QUANTITY,
  productType,
  quantity,
});

export const REMOVE_BIKES_FROM_PRODUCT = 'REMOVE_BIKES_FROM_PRODUCT';
type RemoveBikesFromProductAction = {
  type: typeof REMOVE_BIKES_FROM_PRODUCT;
};
export const removeBikesFromProduct = (): RemoveBikesFromProductAction => ({
  type: REMOVE_BIKES_FROM_PRODUCT,
});

export const RESET_ALL_PRODUCTS = 'RESET_ALL_PRODUCTS';
type ResetAllProducts = {
  type: typeof RESET_ALL_PRODUCTS;
};
export const resetAllProducts = (): ResetAllProducts => ({
  type: RESET_ALL_PRODUCTS,
});

export const SET_RELATION_PRODUCTS = 'SET_RELATION_PRODUCTS';
type SetRelationProducts = {
  type: typeof SET_RELATION_PRODUCTS;
  relationProducts: { [key in ProductsId]?: ProductDataType };
};
export const setRelationProducts = (relationProducts: {
  [key in ProductsId]?: ProductDataType;
}): SetRelationProducts => ({
  type: SET_RELATION_PRODUCTS,
  relationProducts,
});

export type ProductActions =
  | SetProductQuantityAction
  | RemoveBikesFromProductAction
  | ResetAllProducts
  | SetRelationProducts;
