import React from 'react';

import { KEY_NAMES } from 'app/providers/keyNames';

type tabTrapProps = {
  event: React.KeyboardEvent;
  firstItemSelector: string;
  lastItemSelector: string;
  isActive: boolean;
  wrapper: HTMLDivElement | null;
  callback?: () => void;
  skipCheck?: boolean;
};
export const tabTrap = ({
  event,
  firstItemSelector,
  lastItemSelector,
  isActive,
  wrapper,
  callback,
  skipCheck,
}: tabTrapProps) => {
  if (event.key === KEY_NAMES.TAB && wrapper !== null && isActive) {
    const firstItem: HTMLDivElement | null = wrapper.querySelector(firstItemSelector);
    const lastItem: HTMLDivElement | null = wrapper.querySelector(lastItemSelector);

    const isLastItemEqual = skipCheck ? true : event.currentTarget === lastItem;

    if (isLastItemEqual && !event.shiftKey && firstItem !== null) {
      event.preventDefault();
      firstItem.focus();

      if (callback) callback();
    } else if (event.currentTarget === firstItem && event.shiftKey && lastItem !== null) {
      event.preventDefault();
      lastItem.focus();
    }
  }
};
