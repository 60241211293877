import {
  requestUpdateLocation,
  clearLocation,
  setDepartureDate,
  setReturnDate,
  requestSearch,
} from 'app/store/actions';
import { Store } from '@reduxjs/toolkit';
import { StoreProductField } from 'app/models/types/store/product';
import { StoreState } from 'app/models/types';

export type CmsApi = {
  setDepartureCity: (params: { uuid: string; cityName: string }) => void;
  setArrivalCity: (params: { uuid: string; cityName: string }) => void;
  setDepartureDate: (date: number) => void;
  setReturnDate: (date: number) => void;
  onDepartureDateChanged: (params: { date: number; dateLocalized: string }) => void;
  onReturnDateChanged: (params: { date: number; dateLocalized: string }) => void;
  onProductChanged: (params: StoreProductField['types']) => void;
  onDepartureCityChanged: (params: { uuid: string; cityName: string; legacyId: number }) => void;
  onArrivalCityChanged: (params: { uuid: string; cityName: string; legacyId: number }) => void;
  clearDepartureLocation: () => void;
  clearArrivalLocation: () => void;
};

export const cmsApi = (store: Store): CmsApi => ({
  setDepartureCity: ({ uuid, cityName }: { uuid: string; cityName: string }) => {
    store.dispatch(requestUpdateLocation('from', { name: cityName, uuid: uuid }));
  },
  setArrivalCity: ({ uuid, cityName }: { uuid: string; cityName: string }) => {
    store.dispatch(requestUpdateLocation('to', { name: cityName, uuid: uuid }));
  },
  setDepartureDate: (date: number) => {
    store.dispatch(setDepartureDate(date));
    const { general } = store.getState() as StoreState;
    const isAutoUpdate = general.options.autoUpdate;
    if (isAutoUpdate) {
      store.dispatch(requestSearch());
    }
  },
  setReturnDate: (date: number) => {
    store.dispatch(setReturnDate(date));
    const { general } = store.getState() as StoreState;
    const isAutoUpdate = general.options.autoUpdate;
    if (isAutoUpdate) {
      store.dispatch(requestSearch());
    }
  },
  onDepartureDateChanged: (_params: { date: number; dateLocalized: string }) => {},
  onReturnDateChanged: (_params: { date: number; dateLocalized: string }) => {},
  onProductChanged: (_params: StoreProductField['types']) => {},
  onDepartureCityChanged: (_params: { uuid: string; cityName: string; legacyId: number }) => {},
  onArrivalCityChanged: (_params: { uuid: string; cityName: string; legacyId: number }) => {},
  clearDepartureLocation: () => store.dispatch(clearLocation('from')),
  clearArrivalLocation: () => store.dispatch(clearLocation('to')),
});
