import { Brands, getTranslationProps } from '@webc/meeseeks-ui-sdk';
import { languagesSelector, mainBrandUUIDSelector } from 'app/store/selectors';
import { useSelector } from 'react-redux';

export const useTranslations = (
  keys: Record<string, string> | ((x: any) => []),
  getAllTranslations?: boolean,
): ReturnType<typeof getTranslationProps> => {
  const strings = useSelector(languagesSelector);
  const brand = useSelector(mainBrandUUIDSelector);

  return getTranslationProps(strings, {}, !!getAllTranslations, keys, brand as Brands);
};
