import { Button } from '@flixbus/honeycomb-react';
import { useTranslations } from 'app/hooks/useTranslations';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import React from 'react';

export const ConfirmButton = React.forwardRef<
  HTMLButtonElement,
  {
    id?: string;
    onClick: (e: React.SyntheticEvent) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
  }
>(({ onClick, onKeyDown, id }, ref) => {
  const { getTranslationConfirmButtonLabel, getConfirmButtonAriaLabel } = useTranslations({
    getTranslationConfirmButtonLabel: 'search_bundle.search_widget.passengers.popup.btn.confirm',
    getConfirmButtonAriaLabel: a11yLabelsKeys.confirmSelection,
  });

  return (
    <Button
      //@ts-ignore
      innerRef={ref}
      appearance="primary"
      aria-label={getConfirmButtonAriaLabel()}
      onClick={onClick}
      onKeyDown={onKeyDown}
      id={id}
    >
      {getTranslationConfirmButtonLabel()}
    </Button>
  );
});

ConfirmButton.displayName = 'ConfirmButton';
