import { OpenedOverlayType, StoreUiField } from 'app/models/types/store/ui';

export const LOAD_UI = 'LOAD_UI';
export type LoadUiAction = {
  type: typeof LOAD_UI;
};
export const loadUi = () => ({
  type: LOAD_UI,
});

export const WAIT_UI = 'WAIT_UI';
export type WaitUiAction = {
  type: typeof WAIT_UI;
};
export const waitUi = () => ({
  type: WAIT_UI,
});

export const UPDATE_FOCUSED_PART = 'UPDATE_FOCUSED_PART';
export type UpdateFocusedPart = {
  type: typeof UPDATE_FOCUSED_PART;
  focusedPart: StoreUiField['focusedPart'];
};
export const updateFocusedPart = (focusedPart: StoreUiField['focusedPart']): UpdateFocusedPart => ({
  type: UPDATE_FOCUSED_PART,
  focusedPart,
});

export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';
export type ToggleOverlay = {
  type: typeof TOGGLE_OVERLAY;
  data?: OpenedOverlayType;
};
export const toggleOverlay = (data?: OpenedOverlayType): ToggleOverlay => ({
  type: TOGGLE_OVERLAY as typeof TOGGLE_OVERLAY,
  data,
});

export type UiActions = LoadUiAction | WaitUiAction | UpdateFocusedPart | ToggleOverlay;
