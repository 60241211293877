export default {
  AD: 'europe',
  AE: 'asia',
  AF: 'asia',
  AG: 'north_america',
  AI: 'north_america',
  AL: 'europe',
  AM: 'asia',
  AN: 'north_america',
  AO: 'africa',
  AP: 'asia',
  AQ: 'antartica',
  AR: 'south_america',
  AS: 'oceania',
  AT: 'europe',
  AU: 'oceania',
  AW: 'north_america',
  AX: 'europe',
  AZ: 'asia',
  BA: 'europe',
  BB: 'north_america',
  BD: 'asia',
  BE: 'europe',
  BF: 'africa',
  BG: 'europe',
  BH: 'asia',
  BI: 'africa',
  BJ: 'africa',
  BL: 'north_america',
  BM: 'north_america',
  BN: 'asia',
  BO: 'south_america',
  BR: 'south_america',
  BS: 'north_america',
  BT: 'asia',
  BV: 'antartica',
  BW: 'africa',
  BY: 'europe',
  BZ: 'north_america',
  CA: 'north_america',
  CC: 'asia',
  CD: 'africa',
  CF: 'africa',
  CG: 'africa',
  CH: 'europe',
  CI: 'africa',
  CK: 'oceania',
  CL: 'south_america',
  CM: 'africa',
  CN: 'asia',
  CO: 'south_america',
  CR: 'north_america',
  CU: 'north_america',
  CV: 'africa',
  CX: 'asia',
  CY: 'asia',
  CZ: 'europe',
  DE: 'europe',
  DJ: 'africa',
  DK: 'europe',
  DM: 'north_america',
  DO: 'north_america',
  DZ: 'africa',
  EC: 'south_america',
  EE: 'europe',
  EG: 'africa',
  EH: 'africa',
  ER: 'africa',
  ES: 'europe',
  ET: 'africa',
  FI: 'europe',
  FJ: 'oceania',
  FK: 'south_america',
  FM: 'oceania',
  FO: 'europe',
  FR: 'europe',
  FX: 'europe',
  GA: 'africa',
  GB: 'europe',
  GD: 'north_america',
  GE: 'asia',
  GF: 'south_america',
  GG: 'europe',
  GH: 'africa',
  GI: 'europe',
  GL: 'north_america',
  GM: 'africa',
  GN: 'africa',
  GP: 'north_america',
  GQ: 'africa',
  GR: 'europe',
  GS: 'antartica',
  GT: 'north_america',
  GU: 'oceania',
  GW: 'africa',
  GY: 'south_america',
  HK: 'asia',
  HM: 'antartica',
  HN: 'north_america',
  HR: 'europe',
  HT: 'north_america',
  HU: 'europe',
  ID: 'asia',
  IE: 'europe',
  IL: 'asia',
  IM: 'europe',
  IN: 'asia',
  IO: 'asia',
  IQ: 'asia',
  IR: 'asia',
  IS: 'europe',
  IT: 'europe',
  JE: 'europe',
  JM: 'north_america',
  JO: 'asia',
  JP: 'asia',
  KE: 'africa',
  KG: 'asia',
  KH: 'asia',
  KI: 'oceania',
  KM: 'africa',
  KN: 'north_america',
  KP: 'asia',
  KR: 'asia',
  KW: 'asia',
  KY: 'north_america',
  KZ: 'asia',
  LA: 'asia',
  LB: 'asia',
  LC: 'north_america',
  LI: 'europe',
  LK: 'asia',
  LR: 'africa',
  LS: 'africa',
  LT: 'europe',
  LU: 'europe',
  LV: 'europe',
  LY: 'africa',
  MA: 'africa',
  MC: 'europe',
  MD: 'europe',
  ME: 'europe',
  MF: 'north_america',
  MG: 'africa',
  MH: 'oceania',
  MK: 'europe',
  ML: 'africa',
  MM: 'asia',
  MN: 'asia',
  MO: 'asia',
  MP: 'oceania',
  MQ: 'north_america',
  MR: 'africa',
  MS: 'north_america',
  MT: 'europe',
  MU: 'africa',
  MV: 'asia',
  MW: 'africa',
  MX: 'north_america',
  MY: 'asia',
  MZ: 'africa',
  NA: 'africa',
  NC: 'oceania',
  NE: 'africa',
  NF: 'oceania',
  NG: 'africa',
  NI: 'north_america',
  NL: 'europe',
  NO: 'europe',
  NP: 'asia',
  NR: 'oceania',
  NU: 'oceania',
  NZ: 'oceania',
  OM: 'asia',
  PA: 'north_america',
  PE: 'south_america',
  PF: 'oceania',
  PG: 'oceania',
  PH: 'asia',
  PK: 'asia',
  PL: 'europe',
  PM: 'north_america',
  PN: 'oceania',
  PR: 'north_america',
  PS: 'asia',
  PT: 'europe',
  PW: 'oceania',
  PY: 'south_america',
  QA: 'asia',
  RE: 'africa',
  RO: 'europe',
  RS: 'europe',
  RU: 'europe',
  RW: 'africa',
  SA: 'asia',
  SB: 'oceania',
  SC: 'africa',
  SD: 'africa',
  SE: 'europe',
  SG: 'asia',
  SH: 'africa',
  SI: 'europe',
  SJ: 'europe',
  SK: 'europe',
  SL: 'africa',
  SM: 'europe',
  SN: 'africa',
  SO: 'africa',
  SR: 'south_america',
  ST: 'africa',
  SV: 'north_america',
  SY: 'asia',
  SZ: 'africa',
  TC: 'north_america',
  TD: 'africa',
  TF: 'antartica',
  TG: 'africa',
  TH: 'asia',
  TJ: 'asia',
  TK: 'oceania',
  TL: 'asia',
  TM: 'asia',
  TN: 'africa',
  TO: 'oceania',
  TR: 'europe',
  TT: 'north_america',
  TV: 'oceania',
  TW: 'asia',
  TZ: 'africa',
  UA: 'europe',
  UG: 'africa',
  UM: 'oceania',
  US: 'north_america',
  UY: 'south_america',
  UZ: 'asia',
  VA: 'europe',
  VC: 'north_america',
  VE: 'south_america',
  VG: 'north_america',
  VI: 'north_america',
  VN: 'asia',
  VU: 'oceania',
  WF: 'oceania',
  WS: 'oceania',
  YE: 'asia',
  YT: 'africa',
  ZA: 'africa',
  ZM: 'africa',
  ZW: 'africa',
};
