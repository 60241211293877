import React, { FC, useRef } from 'react';
import styles from './MapLink.scss';
import { useSelector } from 'react-redux';
import { localeSelector, themeSelector } from 'app/store/selectors';
import globalThumbnail from './assets/global.png';
import trThumbnail from './assets/tr.png';
import ghThumbnail from './assets/gh.png';
import indiaThumbnail from './assets/india.png';
import brazilThumbnail from './assets/brazil.png';
import usThumbnail from './assets/us.png';
import cn from 'classnames';

export type MapLinkProps = {
  title: string;
  text: string;
};

export const MapLink: FC<MapLinkProps> = ({ title, text }) => {
  const theme = useSelector(themeSelector);
  const locale = useSelector(localeSelector);
  const ref = useRef<HTMLDivElement>(null);
  const isIndiaLocale = locale === 'en_IN';
  const isBrazilLocale = locale === 'pt_BR';
  const isUSLocale = locale === 'en_US' || locale === 'es_US' || locale === 'zh_US';

  const getMapImage = () => {
    if (isIndiaLocale) {
      return indiaThumbnail;
    }

    if (isBrazilLocale) {
      return brazilThumbnail;
    }

    if (isUSLocale) {
      return usThumbnail;
    }

    switch (theme) {
      case 'neptune':
        return ghThumbnail;
      case 'kamil':
        return trThumbnail;
      default:
        return globalThumbnail;
    }
  };

  return (
    <div ref={ref} className={styles.mapWrapper}>
      <div className={styles.mapLink}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={getMapImage()} alt="" />
        </div>

        <div>
          <p className={styles.title}>{title}</p>
          <span className={cn(styles.fakeLink, { [styles.isNeptune]: theme === 'neptune' })}>
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};
