import { ProductsId } from '@webc/meeseeks-ui-sdk';
import { ProductDataType } from 'app/models/types/store/product';

export const getTranslatedSummary = (
  summary: { [key in ProductsId]?: ProductDataType },
  getTranslationFunction: (value: string, options: Record<string, string>, amount: number) => void,
) =>
  Object.keys(summary)
    .map((key) => {
      const translatedSummary = getTranslationFunction(
        `cart.counted.${key}`,
        {},
        summary[key].selectedAmount,
      );

      if (summary[key].selectedAmount === 0) {
        return `${0} ${translatedSummary}`;
      }

      return translatedSummary;
    })
    .join(', ');
