import { groupResultsByContinent, hashAutocompleteResults } from 'app/functions/location';
import {
  AutocompleteResult,
  AutocompleteResultStation,
  SearchMaskDirection,
} from 'app/models/types/store/location';
import {
  popularCitiesSelector,
  recentCitiesSelector,
  autocompleteFilteredSelector,
  continentAutocompleteOrderSelector,
  hideExplorationMapLinkSelector,
  flixTrainSelector,
} from 'app/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslations } from './useTranslations';
import {
  AutocompleteGroupType,
  AutocompleteOptionType,
} from '@flixbus/honeycomb-react/src/components/autocomplete/autocomplete-options/types';

export type ResultsType = 'autocomplete' | 'recent' | 'popular';
type ResultsPropTypes = {
  direction: SearchMaskDirection;
  hasQuery: boolean;
  isFocused: boolean;
  groupTitle: (props: { type: ResultsType; title: string }) => JSX.Element;
};

export const useResults = ({
  direction,
  hasQuery = false,
  isFocused = false,
  groupTitle,
}: ResultsPropTypes) => {
  const { getTranslation } = useTranslations({}, true);
  const popularCities = useSelector(popularCitiesSelector)[direction];
  const recentCities = useSelector(recentCitiesSelector)[direction];
  const autocompleteCities = useSelector(autocompleteFilteredSelector)[direction];
  const continentOrder = useSelector(continentAutocompleteOrderSelector);
  const hideExplorationMapLink = useSelector(hideExplorationMapLinkSelector);
  const isFlixTrainDomain = useSelector(flixTrainSelector);
  const resultsHash = hashAutocompleteResults([
    ...popularCities,
    ...recentCities,
    ...autocompleteCities,
  ]);
  const getSubtitle = (city: AutocompleteResult) =>
    `${city.district ? `${city.district}, ` : ''}${
      getTranslation(city.country)
        ? getTranslation(city.country)
        : city.country
          ? city.country.toUpperCase()
          : // @ts-ignore
            city.countryCode.toUpperCase()
    }`;

  const mapOptions = (results: Map<string, AutocompleteResult[]>, type: ResultsType) => {
    const citiesById = {};
    let currentIndex = 0;
    const autocompleteOptions = [...results.keys()].reduce(
      (acc: AutocompleteGroupType[], curr: string) => {
        const cities =
          results.get(curr)?.reduce((acc: AutocompleteOptionType[], curr: AutocompleteResult) => {
            citiesById[curr.id] = {
              ...curr,
              positionOnList: currentIndex,
              filteredStations: isFlixTrainDomain
                ? curr.stations?.filter((station) => station.is_train)
                : curr.stations,
            };
            currentIndex += 1;
            const areStationsAvailable =
              curr.stations?.length && curr.stations?.length > 1 && type === 'autocomplete';
            let transformedStations: AutocompleteResultStation[] = [];
            if (areStationsAvailable && curr.stations?.length) {
              transformedStations = isFlixTrainDomain
                ? curr.stations?.filter((station) => station.is_train)
                : curr.stations;
            }

            //station mapping for autocomplete option
            const stations = areStationsAvailable
              ? transformedStations.map((station) => {
                  citiesById[station.id] = {
                    station,
                    isStation: true,
                    cityId: curr.id,
                    positionOnList: currentIndex,
                  };
                  currentIndex += 1;
                  return {
                    key: station.id,
                    title: station.name,
                  };
                })
              : [];

            //mapping cities to the autocomplete option and adding that city and its stations to the list of options
            acc.push(
              {
                key: curr.id,
                title: curr.name,
                subtitle: getSubtitle(curr),
              },
              ...stations,
            );
            return acc;
          }, []) || [];
        //adding a list of cities to a given group (continent)
        acc.push({
          group: groupTitle({ type, title: curr }) as unknown as string,
          key: curr,
          options: cities,
        });
        return acc;
      },
      [],
    );
    return {
      options: [...autocompleteOptions, !hideExplorationMapLink && linkMapOption].filter(
        Boolean,
      ) as AutocompleteGroupType[],
      citiesById,
    };
  };

  const linkMapOption: AutocompleteOptionType = {
    title: 'mapItem',
    key: 'mapItem',
  };

  return useMemo(() => {
    let map: Map<string, AutocompleteResult[]> = new Map();
    let firstElement: AutocompleteResult | null = null;
    let type: ResultsType = 'autocomplete';

    if (recentCities.length <= 1 && popularCities.length >= 1 && !hasQuery) {
      map = new Map();
      map.set(getTranslation('search_mask.label.popular_places'), popularCities);
      type = 'popular';
      firstElement = popularCities[0];
      const { options, citiesById } = mapOptions(map, type);
      return { results: map, type, firstElement, options, citiesById };
    }

    if (recentCities.length >= 1 && !hasQuery) {
      map = new Map();
      map.set(getTranslation('search_mask.label.recent_searches'), recentCities);
      type = 'recent';
      firstElement = recentCities[0];
      const { options, citiesById } = mapOptions(map, type);
      return { results: map, type, firstElement, options, citiesById };
    }

    const grouped = groupResultsByContinent(autocompleteCities, continentOrder);
    for (const [key, results] of grouped) {
      map.set(getTranslation(`search_mask.continent.${key}`), results);
      if (!firstElement) {
        firstElement = results[0];
      }
    }

    const { options, citiesById } = mapOptions(map, type);

    return {
      results: map,
      type,
      firstElement,
      options,
      citiesById,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof getTranslation === 'function', resultsHash, hasQuery, isFocused]);
};
