import { createSelector } from '@reduxjs/toolkit';
import { StoreState } from 'app/models/types';
import { setMonth, getDate } from 'date-fns';
import { localeSelector } from './general';
import { isDateToday } from 'app/functions';

export const ZH_CH_DAY = '日';
const ZH_CH_YEAR = '年';

export const languagesSelector = (state: StoreState) => state.translation.strings;

export const todaySelector = createSelector(
  languagesSelector,
  (languages) => languages['search_mask.today'] || '',
);

export const weekdaysShortSelector = (state: StoreState) =>
  state.translation.dates?.weekdaysMin || [];

export const weekdaysSelector = (state: StoreState) => state.translation.dates?.weekdays || [];

export const monthsSelector = (state: StoreState) => state.translation.dates?.months || [];

export const monthsShortSelector = (state: StoreState) =>
  state.translation.dates?.monthsShort || [];

export const monthsTitleSelector = createSelector(
  monthsSelector,
  monthsShortSelector,
  localeSelector,
  (months, monthsShort, locale) => {
    const formattedMonths: string[] = [];
    for (let i = 0; i < 12; i++) {
      const date = setMonth(getDate(new Date()), i);
      if (locale === 'zh_US') {
        formattedMonths.push(`${monthsShort[date.getMonth()]}${ZH_CH_YEAR}`);
      } else {
        formattedMonths.push(`${months[date.getMonth()]}`);
      }
    }
    return formattedMonths;
  },
);
export const dateLabelSelector = createSelector(
  weekdaysShortSelector,
  monthsShortSelector,
  localeSelector,
  todaySelector,
  (weekdaysShort, monthsShort, locale, today) => {
    return (date: Date) => {
      if (!weekdaysShort.length || !monthsShort.length || !today) return '';
      const dayPrefix = isDateToday(date) ? today : weekdaysShort[date.getDay()];
      switch (locale) {
        case 'en_US':
        case 'en_CA':
        case 'es_US':
          return `${dayPrefix}, ${monthsShort[date.getMonth()]} ${date.getDate()}`;
        case 'zh_US':
          return `${dayPrefix} ${monthsShort[date.getMonth()]}${date.getDate()}${ZH_CH_DAY}`;
        case 'lt_LT':
          return `${dayPrefix}, ${monthsShort[date.getMonth()]} ${date.getDate()}`;
        default:
          return `${dayPrefix}, ${date.getDate()} ${monthsShort[date.getMonth()]}`;
      }
    };
  },
);
