import type { RumPublicApi } from '@datadog/browser-rum-core';

// when loaded async, the DD_RUM instance might fail to load
// in that case the 'version' will not be set
// we can detect that to know if DD is ready
const datadogAgentReady = (datadogRum: Partial<RumPublicApi>): boolean => 'version' in datadogRum;

// copy/pasta from
// https://github.com/DataDog/rum-react-integration-examples/blob/7f16f090ab43f90b78e452545a844cc94ad6562b/src/utils/getGlobalObject.ts#L4
const getGlobalObject = (): Window => {
  if (typeof globalThis === 'object') {
    return globalThis as unknown as Window;
  }
  Object.defineProperty(Object.prototype, '_dd_temp_', {
    get() {
      return this as object;
    },
    configurable: true,
  });
  // @ts-ignore _dd_temp is defined using defineProperty
  let globalObject: unknown = _dd_temp_;
  // @ts-ignore _dd_temp is defined using defineProperty
  delete Object.prototype._dd_temp_;
  if (typeof globalObject !== 'object') {
    // on safari _dd_temp_ is available on window but not globally
    // fallback on other browser globals check
    if (typeof self === 'object') {
      globalObject = self;
    } else if (typeof window === 'object') {
      globalObject = window;
    } else {
      globalObject = {};
    }
  }
  return window;
};

export const getDataDogInstance = (): [
  'available' | 'not-available',
  Pick<RumPublicApi, 'addError' | 'addAction' | 'setGlobalContextProperty'>,
] => {
  const global = getGlobalObject();
  if (global.DD_RUM && datadogAgentReady(global.DD_RUM)) {
    return [
      'available',
      {
        addAction: global.DD_RUM.addAction,
        addError: global.DD_RUM.addError,
        setGlobalContextProperty: global.DD_RUM.setGlobalContextProperty,
      },
    ];
  }
  return [
    'not-available',
    {
      addError: () => {},
      addAction: () => {},
      setGlobalContextProperty: () => {},
    },
  ];
};
