import React, { Component, ErrorInfo, FC, ReactNode } from 'react';
import { useDataDog } from 'app/hooks/useDataDog';

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export interface Props {
  fallback: ReactNode;
  children: ReactNode;
  dataDog: ReturnType<typeof useDataDog>;
}

class ErrorBoundaryClass extends Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.dataDog.addError(error, { errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // @todo: try to recover from here
      return null;
    }
    return this.props.children;
  }
}

export const ErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => {
  const dataDog = useDataDog();
  return (
    <ErrorBoundaryClass fallback="" dataDog={dataDog}>
      {children}
    </ErrorBoundaryClass>
  );
};
