import { tokenize } from 'app/functions/string';
import { fetchCityRelationDetails } from './fetchCityRelationDetails';
import { AutocompleteResult } from 'app/models/types/store/location';
import { CitiesDetailsResponseCity } from '@webc/meeseeks-ui-sdk';
import { countryCodesPerLanguage } from 'app/providers/countryCodesPerLanguage';

export const buildRequestQuery = (locale: string) => {
  const queryString = new URLSearchParams();
  let languageCode = locale.replaceAll('_', '-').toLowerCase();

  if (languageCode === 'en') {
    languageCode = 'en-gl';
  } else if (languageCode === 'at') {
    languageCode = 'de-at';
  } else if (languageCode === 'pt') {
    languageCode = 'pt-pt';
  } else if (languageCode === 'lv-lv') {
    languageCode = 'lv';
  } else if (languageCode === 'lt-lt') {
    languageCode = 'lt';
  } else if (languageCode === 'et-ee') {
    languageCode = 'et';
  } else if (languageCode === 'no') {
    languageCode = 'nb';
  } else if (languageCode === 'sr-latn-rs' || languageCode === 'rs') {
    languageCode = 'sr';
  }
  const countryCode = countryCodesPerLanguage[languageCode]?.toUpperCase();

  queryString.set('language', languageCode);

  if (locale !== 'en') {
    queryString.set('country', countryCode);
  }

  queryString.set('limit', '5');

  return queryString;
};

const getCity = (x: CitiesDetailsResponseCity, index: number): AutocompleteResult => ({
  ...x[0],
  legacyId: x[0].legacy_id,
  country: x[0].country_code,
  hash: index.toString(),
  score: 0,
  continent: '',
  _tokenizedLabel: tokenize(x[0].name),
  subdivisionCode: x[0].subdivision_code,
});

export const fetchPopularCities = (
  recommendationURl: string,
  citiesDetailsEndpoint: string,
  locale: string,
  cityId?: string,
): Promise<AutocompleteResult[]> => {
  const requestQuery = buildRequestQuery(locale);
  const url = cityId ? `${recommendationURl}/${cityId}/reachable` : recommendationURl;

  return fetch(`${url}?${requestQuery}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then(async (jsonResponse) => {
      const requests = jsonResponse.result.map((x: { id: number }) => {
        return fetchCityRelationDetails(citiesDetailsEndpoint, x.id, locale);
      });
      const cities: CitiesDetailsResponseCity[] = await Promise.all(requests);
      return cities.map(getCity);
    });
};
