import { StoreUiField } from 'app/models/types/store/ui';
import {
  LOAD_UI,
  TOGGLE_OVERLAY,
  UiActions,
  UPDATE_FOCUSED_PART,
  WAIT_UI,
} from 'app/store/actions';

const defaultState: StoreUiField = {
  loading: true,
  focusedPart: null,
  openedOverlay: null,
};

export default (state = defaultState, action: UiActions) => {
  switch (action.type) {
    case LOAD_UI:
      return {
        ...state,
        loading: false,
      };
    case WAIT_UI:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FOCUSED_PART:
      return {
        ...state,
        focusedPart: action.focusedPart,
      };
    case TOGGLE_OVERLAY:
      return {
        ...state,
        openedOverlay: !state.openedOverlay ? action.data : null,
      };
    default:
      return state;
  }
};
