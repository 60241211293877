const translationMapping = {
  adult: {
    label: 'search.form.label.adult',
    description: 'search_bundle.search_widget.adults_description',
  },
  children: {
    label: 'search.form.label.children',
    description: 'search_bundle.search_widget.children_description',
  },
  children_0_5: {
    label: 'search.form.label.children_0_5',
    description: 'search_bundle.search_widget.children_0_5_description',
  },
  children_0_11: {
    label: 'search.form.label.children_0_11',
    description: 'search_bundle.search_widget.children_0_11_description',
  },
  children_0_12: {
    label: 'passenger.children_0_12',
  },
  children_6_14: {
    label: 'search.form.label.children_6_14',
    description: 'search_bundle.search_widget.children_6_14_description',
  },
  children_6_11: {
    label: 'search.form.label.children_6_11',
    description: 'search_bundle.search_widget.children_6_11_description',
  },
  children_12_17: {
    label: 'search.form.label.children_12_17',
    description: 'search_bundle.search_widget.children_12_17_description',
  },
  children_12_15: {
    label: 'search.form.label.children_12_15',
    description: 'search_bundle.search_widget.children_12_15_description',
  },
  children_13_14: {
    label: 'passenger.children_13_14',
  },
  pensioner: {
    label: 'search.form.label.pensioner',
    description: 'search_bundle.search_widget.pensioner_description',
  },
  student: {
    label: 'search.form.label.student',
    description: 'search_bundle.search_widget.students_description',
  },
  pupil: {
    label: 'search.form.label.pupil',
    description: 'search_bundle.search_widget.pupil_description',
  },
  pwd: {
    label: 'search.form.label.pwd',
    description: 'search_bundle.search_widget.pwd_description',
  },
  bike_slot: {
    label: 'search.form.label.bike_slot',
    description: 'search_bundle.search_widget.bikes_description',
  },
  ch_adult_halb_tax: {
    label: 'search.form.label.ch_adult_halb_tax',
    description: 'search_bundle.search_widget.ch_adult_halb_tax_description',
  },
  ch_adult_general_abonnement: {
    label: 'search.form.label.ch_adult_general_abonnement',
    description: 'search_bundle.search_widget.ch_adult_general_abonnement_description',
  },
  ch_children_6_15: {
    label: 'search.form.label.ch_children_6_15',
    description: 'search_bundle.search_widget.ch_children_6_15_description',
  },
  children_6_17: {
    label: 'search.form.label.children_6_17',
    description: 'search_bundle.search_widget.children_6_17',
  },
  senior: {
    label: 'search.form.label.senior',
    description: 'search_bundle.search_widget.senior',
  },
  wheelchair: {
    label: 'search.form.label.wheelchair',
    description: 'search_bundle.search_widget.wheelchair',
  },
  tr_pwd: {
    label: 'search.form.label.tr_pwd',
    description: 'search_bundle.search_widget.tr_pwd_description',
  },
  tr_retired_person: {
    label: 'search.form.label.tr_retired_person',
  },
  pl_blind_person_guide: {
    description: 'search_bundle.search_widget.pl_blind_person_guide_description',
    label: 'passenger.pl_blind_person_guide',
  },
  pl_war_invalid_carer: {
    description: 'search_bundle.search_widget.pl_war_invalid_carer_description',
    label: 'passenger.pl_war_invalid_carer',
  },
  pl_dependent_carer: {
    description: 'search_bundle.search_widget.pl_dependent_carer_description',
    label: 'passenger.pl_dependent_carer',
  },
  pl_blind_dependent: {
    description: 'search_bundle.search_widget.pl_blind_dependent_description',
    label: 'passenger.pl_blind_dependent',
  },
  pl_border_guard: {
    description: 'search_bundle.search_widget.pl_border_guard_description',
    label: 'passenger.pl_border_guard',
  },
  pl_customs_officer: {
    description: 'search_bundle.search_widget.pl_customs_officer_description',
    label: 'passenger.pl_customs_officer',
  },
  pl_military_police: {
    description: 'search_bundle.search_widget.pl_military_police_description',
    label: 'passenger.pl_military_police',
  },
  pl_child_disabled: {
    description: 'search_bundle.search_widget.pl_child_disabled_description',
    label: 'passenger.pl_child_disabled',
  },
  pl_child_disabled_carer: {
    description: 'search_bundle.search_widget.pl_child_disabled_carer_description',
    label: 'passenger.pl_child_disabled_carer',
  },
  pl_war_invalid_dependent: {
    description: 'search_bundle.search_widget.pl_war_invalid_dependent_description',
    label: 'passenger.pl_war_invalid_dependent',
  },
  pl_soldier: {
    description: 'search_bundle.search_widget.pl_soldier_description',
    label: 'passenger.pl_soldier',
  },
  pl_blind_war_victim: {
    description: 'search_bundle.search_widget.pl_blind_war_victim_description',
    label: 'passenger.pl_blind_war_victim',
  },
  pl_student_disabled: {
    description: 'search_bundle.search_widget.pl_student_disabled_description',
    label: 'passenger.pl_student_disabled',
  },
  pl_combatant: {
    description: 'search_bundle.search_widget.pl_combatant_description',
    label: 'passenger.pl_combatant',
  },
  pl_blind_person: {
    description: 'search_bundle.search_widget.pl_blind_person_description',
    label: 'passenger.pl_blind_person',
  },
  pl_veteran_invalid: {
    description: 'search_bundle.search_widget.pl_veteran_invalid_description',
    label: 'passenger.pl_veteran_invalid',
  },
  pl_war_invalid: {
    description: 'search_bundle.search_widget.pl_war_invalid_description',
    label: 'passenger.pl_war_invalid',
  },
  pl_dependent: {
    description: 'search_bundle.search_widget.pl_dependent_description',
    label: 'passenger.pl_dependent',
  },
  pl_anti_communist_opposition_activist: {
    label: 'search.form.label.pl_anti_communist_opposition_activist',
  },
  pl_police_officer: {
    label: 'passenger.pl_police_officer',
  },
  pl_child_0_4: {
    description: 'search_bundle.search_widget.pl_child_0_4_description',
    label: 'passenger.pl_child_0_4',
  },
  pl_child_5_6: {
    description: 'search_bundle.search_widget.pl_child_5_6_description',
    label: 'passenger.pl_child_5_6',
  },
  br_pwd_free: {
    label: 'passenger.br_pwd_free',
  },
  br_ewli_free: {
    label: 'passenger.br_ewli_free',
  },
  br_ewli_half: {
    label: 'passenger.br_ewli_half',
  },
  br_ywli_free: {
    label: 'passenger.br_ywli_free',
  },
  br_ywli_half: {
    label: 'passenger.br_ywli_half',
  },
  br_children_0_5_free: {
    label: 'passenger.br_children_0_5_free',
  },
  br_children_0_5_full: {
    label: 'passenger.br_children_0_5_full',
  },
  br_children_0_15: {
    label: 'passenger.br_children_0_15',
  },
  br_auditor: {
    label: 'passenger.br_auditor',
  },
  br_child_on_lap: {
    label: 'passenger.br_child_on_lap',
  },
  ua_soldier: {
    label: 'passenger.ua_soldier',
  },
  ua_pitwah: {
    label: 'passenger.ua_pitwah',
  },
  ua_vwd: {
    label: 'passenger.ua_vwd',
  },
  ua_children_lf: {
    label: 'passenger.ua_children_lf',
  },
  ua_children_0_5: {
    label: 'passenger.ua_children_0_5',
  },
  ee_scholar_student: {
    label: 'passenger.ee_scholar_student',
  },
  ee_elderly: {
    label: 'passenger.ee_elderly',
  },
  ee_person_with_disability: {
    label: 'search.form.label.ee_person_with_disability',
    description: 'search_bundle.search_widget.ee_person_with_disability_description',
  },
  cz_disability_level_3: {
    label: 'search.form.label.cz_disability_level_3',
    description: 'search_bundle.search_widget.cz_disability_level_3',
  },
  us_children_2_15: {
    label: 'passenger.children',
    description: 'search_bundle.search_widget.us_children_2_15_description',
  },
  us_veteran: {
    label: 'passenger.us_veteran',
    description: 'search_bundle.search_widget.us_veteran_description',
  },
  mx_teacher: {
    label: 'passenger.mx_teacher',
  },
  mx_children_2_15: {
    label: 'passenger.mx_children_2_15',
  },
  cl_children_0_13: {
    label: 'passenger.cl_children_0_13',
    description: 'search_bundle.search_widget.cl_children_0_13_description',
  },
  cl_children_14_17: {
    label: 'passenger.cl_children_14_17',
    description: 'search_bundle.search_widget.cl_children_14_17_description',
  },
  sct_nec_5_15: {
    label: 'passenger.sct_nec_5_15',
    description: 'search_bundle.search_widget.sct_nec_5_15_description',
  },
  sct_nec_16_21: {
    label: 'passenger.sct_nec_16_21',
    description: 'search_bundle.search_widget.sct_nec_16_21_description',
  },
  sct_nec_60_disabled: {
    label: 'passenger.sct_nec_60_disabled',
    description: 'search_bundle.search_widget.sct_nec_60_disabled_description',
  },
  se_children_0_15: {
    label: 'passenger.se_children_0_15',
    description: 'search_bundle.search_widget.se_children_0_15_description',
  },
  se_young_16_25: {
    label: 'passenger.se_young_16_25',
    description: 'search_bundle.search_widget.se_young_16_25_description',
  },
  in_children_0_17: {
    label: 'passenger.in_children_0_17',
    description: 'search_bundle.search_widget.in_children_0_17_description',
  },
};

export const getProductTranslationKey = (productType: string, type: 'label' | 'description') => {
  if (!translationMapping[productType] || !translationMapping[productType][type]) {
    return productType;
  }

  return translationMapping[productType][type];
};
