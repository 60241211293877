import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  clearAutocomplete,
  requestAutocomplete,
  SetLocationAction,
  SET_LOCATION,
  setRelationProducts,
} from 'app/store/actions';
import {
  arrivalCitySelector,
  cmsApiOnArrivalChangeSelector,
  cmsApiOnDepartureChangeSelector,
  currentProductTypesSelector,
  currentProductTypesExtendedSelector,
} from 'app/store/selectors';

export function* onSetLocationWorker(action: SetLocationAction) {
  // construct and send payload to cmsApi
  const payload = {
    cityName: action.location.name,
    legacyId: action.location.legacyId,
    uuid: action.location.uuid,
  };
  const onDepartureChanged: ReturnType<typeof cmsApiOnDepartureChangeSelector> = yield select(
    cmsApiOnDepartureChangeSelector,
  );
  const onArrivalChanged: ReturnType<typeof cmsApiOnArrivalChangeSelector> = yield select(
    cmsApiOnArrivalChangeSelector,
  );
  const currentProductTypes: ReturnType<typeof currentProductTypesSelector> = yield select(
    currentProductTypesSelector,
  );
  const currentProductTypesExtended: ReturnType<typeof currentProductTypesExtendedSelector> =
    yield select(currentProductTypesExtendedSelector);
  const method = action.direction === 'from' ? onDepartureChanged : onArrivalChanged;
  yield call(method, payload);

  // if we are updating "from" city ensure to flush autocomplete resutls for "to" city
  const arrivalCity: ReturnType<typeof arrivalCitySelector> = yield select(arrivalCitySelector);
  if (action.direction === 'from' && arrivalCity.name) {
    yield put(requestAutocomplete(arrivalCity.name, 'to', true));
  }

  // clear autocomplete results for this direction
  yield put(clearAutocomplete(action.direction));

  yield put(setRelationProducts({ ...currentProductTypes, ...currentProductTypesExtended }));
}

export function* onSetLocation() {
  yield takeEvery<SetLocationAction>(SET_LOCATION, onSetLocationWorker);
}
