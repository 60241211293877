import { getEventEmitter } from '@flixbus/transclusion-eventing';
import {
  removeBikesFromProduct,
  updateDates,
  updateLocations,
  updateSearchParams,
} from 'app/store/actions';
import { Dispatch } from '@reduxjs/toolkit';

// EXTERNAL EVENTS
const EVENT_REMOVE_BIKES = 'search.results.remove_bikes';
const EVENT_OTHER_DATE_SELECTED = 'search.results.other_date_selected';
const EVENT_UPDATE_CITIES = 'search.results.update_cities';
const EVENT_UPDATE_SEARCH_PARAMS_IN_URL = 'search.results.update_search_params_in_url';

export const registerEventListeners = (dispatch: Dispatch) => {
  const eventEmitter = getEventEmitter();

  eventEmitter.on(EVENT_REMOVE_BIKES, () => {
    dispatch(removeBikesFromProduct());
  });

  eventEmitter.on(EVENT_OTHER_DATE_SELECTED, ({ tripIndex, newDateUTC }) => {
    dispatch(updateDates(tripIndex, newDateUTC));
  });

  eventEmitter.on(EVENT_UPDATE_CITIES, ({ fromCityDetails, toCityDetails }) => {
    dispatch(updateLocations(fromCityDetails, toCityDetails));
  });

  eventEmitter.on(EVENT_UPDATE_SEARCH_PARAMS_IN_URL, () => {
    dispatch(updateSearchParams());
  });
};
