import { takeEvery, select, put } from 'redux-saga/effects';
import {
  REQUEST_SEARCH,
  RequestSearchAction,
  SET_RELATION_PRODUCTS,
  storeRecentCity,
} from 'app/store/actions';
import {
  getPayloadForSearchResultsRedirect,
  isSearchInvalid,
  redirectToSearchResultsPage,
} from 'app/functions/general';
import {
  arrivalCitySelector,
  departureCitySelector,
  departureDateTimestampSelector,
  featuresSelector,
  localeSelector,
  optionsSelector,
  passengerCountSelector,
  selectedProductsSelector,
  selectedReturnDateSelector,
  selectedRouteNameSelector,
  wheelchairSelectedSelector,
} from 'app/store/selectors';
import { emitRequestSearchEvent } from 'app/events';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { CityDetailsResult, PartialSearchMaskLocation } from 'app/models/types/store/location';
import { mapLocationToAutocomplete } from 'app/functions';
import { emitSearchForStationEvent } from 'app/events/emitSearchForStationEvent';
import { take } from 'redux-saga-test-plan/matchers';

const DIRECTION_TO = 'to';
const DIRECTION_FROM = 'from';

export function* handleRequestSearch({ noRedirect, isNewCity }: RequestSearchAction) {
  if (isNewCity) {
    yield take(SET_RELATION_PRODUCTS);
  }
  const options: SearchMaskMountOptions = yield select(optionsSelector);
  const features: { [key: string]: boolean } = yield select(featuresSelector);

  const departureCity: CityDetailsResult | PartialSearchMaskLocation =
    yield select(departureCitySelector);
  const arrivalCity: CityDetailsResult | PartialSearchMaskLocation =
    yield select(arrivalCitySelector);
  const routeName: string = yield select(selectedRouteNameSelector);
  const departureDate: number = yield select(departureDateTimestampSelector);
  const returnDate: number | null = yield select(selectedReturnDateSelector);
  const selectedProducts: Record<string, any> = yield select(selectedProductsSelector);
  const locale: string = yield select(localeSelector);
  const passengerCount: number = yield select(passengerCountSelector);
  const wheelchairsCount: number = yield select(wheelchairSelectedSelector);

  if (
    isSearchInvalid({
      passengerCount,
      departureDate,
      wheelchairsCount,
      departureCity: departureCity as CityDetailsResult,
      arrivalCity: arrivalCity as CityDetailsResult,
    })
  ) {
    return;
  }

  yield put(
    storeRecentCity(DIRECTION_FROM, mapLocationToAutocomplete(departureCity as CityDetailsResult)),
  );
  yield put(
    storeRecentCity(DIRECTION_TO, mapLocationToAutocomplete(arrivalCity as CityDetailsResult)),
  );

  const normalizedPayload = getPayloadForSearchResultsRedirect({
    departureCity,
    arrivalCity,
    departureDate,
    returnDate,
    locale,
    routeName,
    selectedProducts,
  });

  if (!!arrivalCity.stationId || !!departureCity.stationId) {
    emitSearchForStationEvent();
  }
  emitRequestSearchEvent(normalizedPayload);
  if (noRedirect) {
    return;
  }
  redirectToSearchResultsPage(normalizedPayload, options, features);
}

export function* requestSearchWatcher() {
  yield takeEvery(REQUEST_SEARCH, handleRequestSearch);
}
