if (process.env.NODE_ENV === 'test') {
  require('app/styles/search-mask-base.css'); // eslint-disable-line
} else {
  require('app/styles/search-mask-base.css?raw'); // eslint-disable-line
}

import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Store } from '@reduxjs/toolkit';

import {
  getEventEmitter,
  integrate as integrateTransclusionEventing,
} from '@flixbus/transclusion-eventing';
import { getStore } from 'app/store';
import { SearchMask } from 'app/components/ui/SearchMask';
import { getOptions } from 'app/options';
import { Debug } from 'app/components/debug/Debug';
import { registerEventListeners } from 'app/events/eventListeners';
import { fetchOptions } from 'app/providers';
import {
  toggleFeature as toggleFeatureAction,
  updateOptions,
  retrieveRecentCities,
  loadPopularCities,
  setCmsApiInstance,
} from 'app/store/actions';
import * as shortcuts from 'app/events/shortcuts';
import * as api from 'app/api';
import { CmsApi, cmsApi } from 'app/api';
import { getSearchMaskValuesFromQueryString } from './functions';
import { ErrorBoundary } from 'app/components/boundaries/ErrorBoundary';
import { isIsolated, target } from './functions/environment/isIsolated';
import { createRoot } from 'react-dom/client';

const onRegister = (store: Store) => {
  registerEventListeners(store.dispatch);
};

type SearchMaskWrapperProps = {
  store: Store;
  debug?: null | React.ReactNode;
};

export const SearchMaskWrapper: React.FC<SearchMaskWrapperProps> = ({ store, debug }) => (
  <Provider store={store}>
    <ErrorBoundary>
      {debug}
      <SearchMask />
    </ErrorBoundary>
  </Provider>
);

SearchMaskWrapper.defaultProps = {
  debug: null,
};

const store = getStore({
  general: { options: getOptions({ initialSearchMaskValues: {} }), server: false, features: {} },
});
const cmsApiInstance = cmsApi(store);
store.dispatch(setCmsApiInstance(cmsApiInstance));
// This function is provided in the global scope and is
// the first function that should be called when
// trying to mount the component. It's responsible
// of starting the application and will also set up
// HMR in development.

/**
 * Mount the Search Mask Component.
 *
 * @param {object} options The object that contains the options
 * @param {string} [options.searchResultsHost="dynamic"] The shop URL to redirect to
 * when the user searches. If left empty, the search mask will try to determine the correct host.
 * @param {string} [options.variant="default"] The theme of the search mask. Can be `default`,
 * `frontpage`, and `compact`.
 * @param {string} [options.locale="en"] The language of the search maskproducts
 * @param {object} [options.initialSearchMaskValues={}] Initial values of the Search Mask
 * @param {number} [options.initialSearchMaskValues.departureCity=88] Selected departure city ID
 * @param {number} [options.initialSearchMaskValues.arrivalCity=94] Selected arrival city ID
 * @param {number} [options.initialSearchMaskValues.departureDate=null] Selected departure date
 * timestamp
 * @param {number} [options.initialSearchMaskValues.returnDate=null] Selected return date timestamp
 * @param {object} [options.initialSearchMaskValues.products=null] An object of product ids and
 * quantities. For example `{adult: 1}`.
 * @param {boolean} [options.handleOwnRequestSearchEvent=false] If `true`, the search mask will
 * redirect to the host when the search button is pressed. If this option is not specified, the
 * integrator must handle the search event and react accordingly. For more information about the
 * emitted events, check [the related documentation](03-events.md)
 * @param {boolean} [options.flixTrain=false] True if the component is included in flixTrain domain
 */
const mount = (options = { initialSearchMaskValues: {} }) => {
  const validatedOptions = getOptions(options);

  store.dispatch(
    updateOptions({
      ...validatedOptions,
    }),
  );

  store.dispatch(retrieveRecentCities());
  store.dispatch(loadPopularCities('from'));

  onRegister(store);

  const debug = isIsolated ? <Debug /> : null;

  if (target) {
    const root = createRoot(target);
    root.render(<SearchMaskWrapper store={store} debug={debug} />);
  }
};

const toggleFeature = (name: string, value: boolean) => {
  store.dispatch(toggleFeatureAction(name, value));
};

export type WindowSearchMaskComponent = {
  mount: () => void;
  toggleFeature: (name: string, value: boolean) => void;
  cmsApi: CmsApi;
};

// The mount function is added to the global scope
// by using a variable name reserved to search.
if (window) {
  window.searchMaskComponent = {
    mount,
    toggleFeature,
    ...shortcuts,
    ...api,
    cmsApi: cmsApi(store),
  };
}

// integrate the Transclusion Eventing into the window
integrateTransclusionEventing();

// If the application is running in isolated environment, it's safe to immediately
// mount the component to avoid doing so manually in the index.html file.
if (isIsolated) {
  /* eslint-disable */
  getEventEmitter().onAny((eventName, payload) => {
    console.log(
      `%cevent %c${eventName}`,
      'padding: 3px; font-size: 1.1em;',
      'padding: 3px; color: #66FDCB; background-color:#1D262F; font-size: 1.1em;',
    );
    console.log(payload);
  });
  mount(getOptions());
}

type SearchMaskComponentProps = {
  options: any;
};

export const SearchMaskComponent: React.FC<SearchMaskComponentProps> = ({ options }) => {
  const mergedOptions = {
    ...options,
    ...(window.searchMaskOptions && window.searchMaskOptions),
  };
  const [$store] = useState(() => {
    store.dispatch(
      updateOptions({
        ...mergedOptions,
      }),
    );
    store.dispatch(retrieveRecentCities());
    store.dispatch(loadPopularCities('from'));
    return store;
  });

  useEffect(() => {
    onRegister($store);
  }, []);

  return (
    <div id="search-mask-component" className="dd-privacy-allow">
      <SearchMaskWrapper store={$store} />
    </div>
  );
};

export const init = fetchOptions;
export const getValuesFromQueryString = getSearchMaskValuesFromQueryString;
export * from './prerender';
