import {
  FEATURE_AUTO_UPDATE_DISABLED,
  FEATURE_DISTRIBUSION,
  FEATURE_ONLY_TRAIN_CITIES,
  FEATURE_US_VETERANS_PROMOTED,
} from 'app/config/features';
import { StoreState } from 'app/models/types';
import { createSelector } from '@reduxjs/toolkit';
import { StoreProductField } from 'app/models/types/store/product';

import { userTypeSelector } from './user';
import { DEFAULT_BRAND_UUID } from '@webc/meeseeks-ui-sdk';

export const optionsSelector = (state: StoreState) => state.general.options;
export const localeSelector = (state: StoreState) => state.general.options.locale;
export const serverSelector = (state: StoreState) => state.general.server;
export const featuresSelector = (state: StoreState) => state.general.features;

export const darkenPageSelector = createSelector(optionsSelector, (options) => options.darkenPage);

export const featureDistribusionSelector = createSelector(
  featuresSelector,
  (features) => features[FEATURE_DISTRIBUSION],
);

export const distribusionEnabledSelector = createSelector(
  featureDistribusionSelector,
  userTypeSelector,
  (featureDistribusion, userType) =>
    featureDistribusion && (userType === 'customer' || userType === 'logged-in-customer'),
);

export const featureOnlyTrainCitiesSelector = createSelector(
  featuresSelector,
  (features) => features[FEATURE_ONLY_TRAIN_CITIES],
);
export const featureAutoUpdateDisabledSelector = createSelector(
  featuresSelector,
  (features) => features[FEATURE_AUTO_UPDATE_DISABLED],
);

export const featureUsVeteransPromotedSelector = createSelector(
  featuresSelector,
  (features) => features[FEATURE_US_VETERANS_PROMOTED],
);

export const themeSelector = createSelector(optionsSelector, (opts) => opts.theme);

export const variantSelector = createSelector(optionsSelector, (options) => options.variant);

export const flixTrainSelector = createSelector(optionsSelector, (options) => options.flixTrain);

export const continentAutocompleteOrderSelector = createSelector(
  optionsSelector,
  (opts) => opts.continentAutocompleteOrder,
);

export const autoUpdateSelector = createSelector(
  optionsSelector,
  featureAutoUpdateDisabledSelector,
  ({ autoUpdate }, isDisabledByCookie) => {
    if (isDisabledByCookie) {
      return false;
    }

    return autoUpdate;
  },
);

/*
  CmsApi mount options are implicitly merged via selectors
  this allows mount option to override the defaults which are set dynamically
  (hence possibly undefined)
  We could maybe make this behaviour more explicit by merging options on mount.
*/

export const cmsApiOnDepartureChangeSelector = (
  state: StoreState,
): ((params: { uuid: string; cityName: string; legacyId: number }) => void) =>
  state.general.options.cmsApi?.onDepartureCityChanged ||
  state.general.cmsApiInstance?.onDepartureCityChanged ||
  function () {};

export const cmsApiOnArrivalChangeSelector = (
  state: StoreState,
): ((params: { uuid: string; cityName: string; legacyId: number }) => void) =>
  state.general.options.cmsApi?.onArrivalCityChanged ||
  state.general.cmsApiInstance?.onArrivalCityChanged ||
  function () {};

export const cmsApiOnProductChangeSelector = (
  state: StoreState,
): ((params: StoreProductField['types']) => void) =>
  state.general.options.cmsApi?.onProductChanged ||
  state.general.cmsApiInstance?.onProductChanged ||
  function () {};

export const cmsApiOnDepartureDateChangeSelector = (
  state: StoreState,
): ((params: { date: number; dateLocalized: string }) => void) =>
  state.general.options.cmsApi?.onDepartureDateChanged ||
  state.general.cmsApiInstance?.onDepartureDateChanged ||
  function () {};

export const cmsApiOnReturnDateChangeSelector = (
  state: StoreState,
): ((params: { date: number; dateLocalized: string }) => void) =>
  state.general.options.cmsApi?.onReturnDateChanged ||
  state.general.cmsApiInstance?.onReturnDateChanged ||
  function () {};

export const mainBrandUUIDSelector = createSelector(
  optionsSelector,
  (options) => options.mainBrandUUID || DEFAULT_BRAND_UUID,
);

export const hideExplorationMapLinkSelector = createSelector(
  optionsSelector,
  (options) => options.hideExplorationMapLink || false,
);

export const showStationSuggestionSelector = createSelector(
  optionsSelector,
  (options) => options.showStationsInAutocomplete,
);
