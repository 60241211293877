import { extendedProductRules, UserType } from '@webc/meeseeks-ui-sdk';

export const getGroupsTripsAvailability = (
  departureCountryCode: string | undefined,
  arrivalCountryCode: string | undefined,
  userType: UserType,
) => {
  const countryId = `${departureCountryCode}-${arrivalCountryCode}`;
  const areGroupTripAvailable =
    extendedProductRules[countryId] && extendedProductRules[countryId][userType]?.hasGroupTrips;

  return !!areGroupTripAvailable;
};
