import { StoreTranslationsField } from 'app/models/types/store/translations';
import { REQUEST_TRANSLATIONS_SUCCEEDED, TranslationActions } from 'app/store/actions';

const defaultState: StoreTranslationsField = {
  strings: {},
};

export default (state = defaultState, action: TranslationActions): StoreTranslationsField => {
  switch (action.type) {
    case REQUEST_TRANSLATIONS_SUCCEEDED:
      return {
        ...state,
        strings: action.strings,
        dates: action.dates,
      };
    default:
      return state;
  }
};
