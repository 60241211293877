import { call, select, takeEvery } from 'redux-saga/effects';
import {
  SET_DEPARTURE_DATE,
  SET_RETURN_DATE,
  SetDepartureDateAction,
  SetReturnDateAction,
} from 'app/store/actions';
import {
  cmsApiOnDepartureDateChangeSelector,
  cmsApiOnReturnDateChangeSelector,
  dateLabelSelector,
} from 'app/store/selectors';
import { fromUnixTime } from 'date-fns';

export function* onSetDateWorker(action: SetDepartureDateAction | SetReturnDateAction) {
  const onDepartureDateChanged: ReturnType<typeof cmsApiOnDepartureDateChangeSelector> =
    yield select(cmsApiOnDepartureDateChangeSelector);
  const onReturnDateChanged: ReturnType<typeof cmsApiOnReturnDateChangeSelector> = yield select(
    cmsApiOnReturnDateChangeSelector,
  );
  const dateLabel: ReturnType<typeof dateLabelSelector> = yield select(dateLabelSelector);
  const dateLocalized = dateLabel(fromUnixTime(action.date));
  const payload = {
    date: action.date,
    dateLocalized,
  };

  const method =
    action.type === 'SET_DEPARTURE_DATE' ? onDepartureDateChanged : onReturnDateChanged;
  yield call(method, payload);
}

export function* onSetDepartureDate() {
  yield takeEvery<SetDepartureDateAction>(SET_DEPARTURE_DATE, onSetDateWorker);
}

export function* onSetArrivalDate() {
  yield takeEvery<SetReturnDateAction>(SET_RETURN_DATE, onSetDateWorker);
}
