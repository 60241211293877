import { RefObject, useEffect } from 'react';

type UseScrollToElementProps = {
  element: RefObject<HTMLElement>;
  active: boolean;
  elementSeen: boolean;
  onScrollToElement: () => void;
};
export const useScrollToElement = ({
  element,
  active,
  elementSeen,
  onScrollToElement,
}: UseScrollToElementProps) => {
  useEffect(() => {
    if (element?.current && active && !elementSeen) {
      element.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      onScrollToElement();
    }
  }, [element, active, elementSeen, onScrollToElement]);
};
