import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { CmsApi } from 'app/api/cmsApi';

export const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
export type UpdateOptionsAction = {
  type: typeof UPDATE_OPTIONS;
  options: SearchMaskMountOptions;
};
export const updateOptions = (options: SearchMaskMountOptions): UpdateOptionsAction => ({
  type: UPDATE_OPTIONS,
  options,
});

export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export type RequestSearchAction = {
  type: typeof REQUEST_SEARCH;
  noRedirect?: boolean;
  isNewCity?: boolean;
};
export const requestSearch = (noRedirect?: boolean, isNewCity?: boolean): RequestSearchAction => ({
  type: REQUEST_SEARCH,
  noRedirect,
  isNewCity,
});

export const TOGGLE_FEATURE = 'TOGGLE_FEATURE';
export type ToggleFeatureAction = {
  type: typeof TOGGLE_FEATURE;
  name: string;
  value: boolean;
};
export const toggleFeature = (name: string, value: boolean): ToggleFeatureAction => ({
  type: TOGGLE_FEATURE,
  name,
  value,
});

export const SET_CMS_API_INSTANCE = 'SET_CMS_API_INSTANCE';
export type SetCmsApiInstanceAction = {
  type: typeof SET_CMS_API_INSTANCE;
  cmsApiInstance: CmsApi;
};
export const setCmsApiInstance = (cmsApiInstance: CmsApi): SetCmsApiInstanceAction => ({
  type: SET_CMS_API_INSTANCE,
  cmsApiInstance,
});

export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS';
export const updateSearchParams = () => ({
  type: UPDATE_SEARCH_PARAMS,
});

export type GeneralActions =
  | UpdateOptionsAction
  | RequestSearchAction
  | ToggleFeatureAction
  | SetCmsApiInstanceAction;
