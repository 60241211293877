import { ComponentType } from 'react';

type fnType = Promise<{ default: ComponentType<any> }>;

export const retryCall = (fn: () => fnType, retriesLeft = 10, interval = 1000): fnType =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);

            return;
          }

          retryCall(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
