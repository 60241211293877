import { requestSearch, UPDATE_SEARCH_PARAMS } from 'app/store/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { updateSearchMaskValues } from 'app/store/sagas/general/updateSearchMaskValues';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { getQueryString, getSearchMaskValuesFromQueryString } from 'app/functions';
import { optionsSelector } from 'app/store/selectors';

export function* onUpdateSearchParamsWorker() {
  const options: ReturnType<typeof optionsSelector> = yield select(optionsSelector);
  const searchMaskValues = getSearchMaskValuesFromQueryString(
    getQueryString(),
  ) as SearchMaskMountOptions['initialSearchMaskValues'];
  yield call(updateSearchMaskValues, searchMaskValues, false, options);
  yield put(requestSearch(true));
}

export function* onUpdateSearchParams() {
  yield takeEvery(UPDATE_SEARCH_PARAMS, onUpdateSearchParamsWorker);
}
