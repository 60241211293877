import { useTranslations } from 'app/hooks/useTranslations';
import { SearchMaskDirection } from 'app/models/types/store/location';
import { autocompleteSelector, selectedLocationsSelector } from 'app/store/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useTripLocation = (direction: SearchMaskDirection) => {
  const selected = useSelector(selectedLocationsSelector)[direction];
  const { getLoadingTranslation, getAutocompleteErrorTranslation } = useTranslations({
    getLoadingTranslation: 'search_mask.loading_autocomplete',
    getAutocompleteErrorTranslation: 'search_mask.error.autocomplete',
  });
  const autocomplete = useSelector(autocompleteSelector)[direction];

  const [value, setValue] = useState<string>(selected.name || '');

  let message = null;
  if (autocomplete.error.present) {
    message = getAutocompleteErrorTranslation();
  }
  if (autocomplete.loading) {
    message = getLoadingTranslation();
  }

  useEffect(() => {
    if (selected) {
      setValue(selected.stationName || selected.name || '');
    }
  }, [selected]);

  return {
    value,
    setValue,
    message,
  };
};
