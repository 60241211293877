import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  SET_PRODUCT_QUANTITY,
  SetProductQuantityAction,
  setRelationProducts,
} from 'app/store/actions';
import {
  cmsApiOnProductChangeSelector,
  selectedProductsSelector,
  currentProductTypesSelector,
  currentProductTypesExtendedSelector,
} from 'app/store/selectors';
import { StoreProductField } from 'app/models/types/store/product';

export function* onSetProductQuantityWorker() {
  const products: StoreProductField['types'] = yield select(selectedProductsSelector);

  const onProductChanged: ReturnType<typeof cmsApiOnProductChangeSelector> = yield select(
    cmsApiOnProductChangeSelector,
  );
  const currentProductTypes: ReturnType<typeof currentProductTypesSelector> = yield select(
    currentProductTypesSelector,
  );
  const currentProductTypesExtended: ReturnType<typeof currentProductTypesExtendedSelector> =
    yield select(currentProductTypesExtendedSelector);

  yield call(onProductChanged, products);
  yield put(setRelationProducts({ ...currentProductTypes, ...currentProductTypesExtended }));
}

export function* onSetProductQuantity() {
  yield takeEvery<SetProductQuantityAction>(SET_PRODUCT_QUANTITY, onSetProductQuantityWorker);
}
