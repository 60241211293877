import {
  UPDATE_OPTIONS,
  TOGGLE_FEATURE,
  GeneralActions,
  SET_CMS_API_INSTANCE,
} from 'app/store/actions';
import { features as defaultFeatures } from 'app/config/features';
import { StoreGeneralField } from 'app/models/types/store/general';
import { defaultOptions } from 'app/options';

const defaultState: StoreGeneralField = {
  options: defaultOptions,
  server: false,
  features: {
    ...defaultFeatures,
  },
};

export default (state = defaultState, action: GeneralActions) => {
  switch (action.type) {
    case UPDATE_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options,
        },
        features: {
          ...state.features,
          ...(action.options.features && action.options.features),
        },
      };

    case TOGGLE_FEATURE: {
      return {
        ...state,
        features: {
          ...state.features,
          [action.name]: action.value,
        },
      };
    }

    case SET_CMS_API_INSTANCE: {
      return {
        ...state,
        cmsApiInstance: action.cmsApiInstance,
      };
    }

    default:
      return state;
  }
};
