import { getIsGlobalTripSearch, SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import {
  getPayloadForSearchResultsRedirect,
  getQueryString,
  getSearchMaskValuesFromQueryString,
  redirectToSearchResultsPage,
} from 'app/functions';

import { StoreTranslationsDateField } from 'app/models/types/store/translations';
import { fetchDatesTranslations, fetchTranslations } from 'app/providers';
import {
  loadUi,
  requestTranslationsSucceeded,
  UPDATE_OPTIONS,
  UpdateOptionsAction,
} from 'app/store/actions';
import {
  optionsSelector,
  departureCitySelector,
  arrivalCitySelector,
  featuresSelector,
  selectedProductsSelector,
  departureDateTimestampSelector,
  selectedReturnDateSelector,
  selectedRouteNameSelector,
} from 'app/store/selectors';
import { takeLatest, select, put, call, all } from 'redux-saga/effects';
import { updateSearchMaskValues } from 'app/store/sagas/general/updateSearchMaskValues';
import { CityDetailsResult, PartialSearchMaskLocation } from 'app/models/types/store/location';

export function* requestTranslations(locale: string) {
  try {
    const [translationsResponse, translationsDatesResponse]: [
      { [key: string]: string },
      StoreTranslationsDateField,
    ] = yield all([call(fetchTranslations, locale), call(fetchDatesTranslations, locale)]);

    yield put(requestTranslationsSucceeded(translationsResponse, translationsDatesResponse));
  } catch (error) {
    // @todo: handle error
  }
}

export function* onUpdateOptionsSagaWorker(action: UpdateOptionsAction) {
  const oldOptions: ReturnType<typeof optionsSelector> = yield select(optionsSelector);
  const newOptions: SearchMaskMountOptions = { ...oldOptions, ...action.options };
  const locale = newOptions.locale;

  const searchMaskValues = {
    ...action.options?.initialSearchMaskValues,
    ...getSearchMaskValuesFromQueryString(getQueryString()),
  } as SearchMaskMountOptions['initialSearchMaskValues'];

  yield call(updateSearchMaskValues, searchMaskValues, true, oldOptions);

  yield all([requestTranslations(locale)]);

  const departureCity: CityDetailsResult | PartialSearchMaskLocation =
    yield select(departureCitySelector);
  const arrivalCity: CityDetailsResult | PartialSearchMaskLocation =
    yield select(arrivalCitySelector);
  const selectedProducts: Record<string, any> = yield select(selectedProductsSelector);
  const features: { [key: string]: boolean } = yield select(featuresSelector);
  const departureDate: number = yield select(departureDateTimestampSelector);
  const returnDate: number | null = yield select(selectedReturnDateSelector);
  const routeName: string = yield select(selectedRouteNameSelector);

  if (
    getIsGlobalTripSearch({
      locale: newOptions.locale,
      departureCountryCode: newOptions.initialSearchMaskValues.departureCountryCode,
      arrivalCountryCode: newOptions.initialSearchMaskValues.arrivalCountryCode,
    })
  ) {
    redirectToSearchResultsPage(
      getPayloadForSearchResultsRedirect({
        departureCity,
        arrivalCity,
        routeName,
        locale,
        departureDate,
        returnDate,
        selectedProducts,
      }),
      newOptions,
      features,
    );
  } else {
    yield put(loadUi());
  }
}

export function* onUpdateOptions() {
  yield takeLatest<UpdateOptionsAction>(UPDATE_OPTIONS, onUpdateOptionsSagaWorker);
}
