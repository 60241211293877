import { getQueryString } from 'app/functions/environment';
import { getSearchMaskValuesFromQueryString } from 'app/functions/general';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';

export const variants = [
  {
    value: 'default',
    label: 'Default',
  },
  {
    value: 'frontpage',
    label: 'Frontpage',
  },
  {
    value: 'compact',
    label: 'Compact',
  },
  {
    value: 'fullWidth',
    label: 'Full width',
  },
];

// Default options
export const defaultOptions: SearchMaskMountOptions = {
  autocompleteUrl: 'https://global.api.flixbus.com/search/autocomplete/cities',
  citiesDetailsEndpoint: 'https://global.api.flixbus.com/search/service/cities/details',
  recommendationUrl: 'https://global.api.flixbus.com/cms/cities',
  variant: process.env.ROBOTNIK_BUILD ? 'fullWidth' : 'default',
  locale: 'en',
  handleOwnRequestSearchEvent: false,
  flixTrain: false,
  onePageSearch: false,
  includeWebtrekkParameters: true,
  theme: 'flix',
  userType: 'customer',
  partner: '',
  searchResultsHostParams: [],
  continentAutocompleteOrder: [],
  autoUpdate: false,
  // @ts-ignore departure/arrival city should be optional in SDK
  initialSearchMaskValues: {
    departureDate: null,
    returnDate: null,
    products: {
      adult: 1,
    },
  },
  showStationsInAutocomplete: true,
};

// DO NOT USE THIS FUNCTION DIRECTLY.
// The options are available in the context, see related documentation
// of providing the options is of the React context. That way, we can identify
// bugs easily and maintaining the options infrastructure will be easier.
export const getOptions = (
  providedOptions: { initialSearchMaskValues: Partial<SearchMaskMountOptions> } = {
    initialSearchMaskValues: {},
  },
) => {
  // Create a new variable as some changes might occur in particular situations.
  const validatedOptions = providedOptions;

  const initialSearchMaskValues = {
    ...defaultOptions.initialSearchMaskValues,
    ...validatedOptions.initialSearchMaskValues,
    ...getSearchMaskValuesFromQueryString(getQueryString()),
  };

  return {
    ...defaultOptions,
    ...validatedOptions,
    initialSearchMaskValues,
  };
};
