import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { onFieldOpened, onFieldClosed } from 'app/events/shortcuts';
import {
  autoUpdateSelector,
  darkenPageSelector,
  openedOverlaySelector,
  optionsSelector,
  themeSelector,
} from 'app/store/selectors';
import { useSelector } from 'react-redux';
import { emitSearchMaskReadyEvent } from 'app/events';
import { DateRangeWrapper } from 'app/components/date/DateRangeWrapper/DateRangeWrapper';
import { SearchButton } from 'app/components/ui/SearchButton';
import { TripLocationWrapper } from 'app/containers/TripLocationWrapper';
import TripProductPicker from 'app/containers/TripProductPicker';
import { TripModePicker } from 'app/containers/TripModePicker/TripModePicker';
import { Head } from 'app/containers/Head';

import '@flixbus/honeycomb/dist/scss/themes/theme-flix.scss';
import '@flixbus/honeycomb/dist/scss/themes/theme-kamil.scss';
import '@flixbus/honeycomb/dist/scss/themes/theme-neptune.scss';
import styles from './SearchMask.scss';
import { useDataDog } from 'app/hooks/useDataDog';
import { getEventEmitter } from '@flixbus/transclusion-eventing';
import { TripLocationMobilePanel } from 'app/components/tripLocation/TripLocationMobilePanel/TripLocationMobilePanel';
import { ProductMobilePanel } from 'app/components/product/ProductMobilePanel/ProductMobilePanel';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';

export const SearchMask: FC = () => {
  const { variant } = useSelector(optionsSelector);
  const dataDog = useDataDog();

  const isPanelOpened = !!useSelector(openedOverlaySelector);

  const [isOnlyElement, setIsOnlyElement] = useState(false);
  const [localVariant, setLocalVariant] = useState(variant);
  const [localTheme, setLocalTheme] = useState<SearchMaskMountOptions['theme']>('flix');

  const [pageDarkened, setPageDarkened] = useState(false);

  const isDarkenPageEnabled = useSelector(darkenPageSelector);
  const theme = useSelector(themeSelector);

  const isAutoUpdate = useSelector(autoUpdateSelector);

  const onPageLoad = () => {
    const mainElementExist =
      !!document.querySelectorAll('[role="main"]').length ||
      !!document.getElementsByTagName('main').length;
    setIsOnlyElement(!mainElementExist);
  };

  useEffect(() => {
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  useEffect(() => {
    setLocalVariant(variant);
  }, [variant]);

  useEffect(() => {
    setLocalTheme(theme);
  }, [theme]);

  useEffect(() => {
    const listener = function (extraContext: Record<string, unknown>) {
      // @ts-ignore
      dataDog.addAction((this.event as string).replace('search.search-mask.', ''), extraContext);
    };
    getEventEmitter().on('search.search-mask.*', listener);
    return () => {
      getEventEmitter().off('search.search-mask.*', listener);
    };
  });
  useEffect(() => {
    emitSearchMaskReadyEvent();
    onFieldOpened(() => {
      if (isDarkenPageEnabled) {
        setPageDarkened(true);
      }
    });
    onFieldClosed(() => {
      setPageDarkened(false);
    });
  }, [isDarkenPageEnabled]);

  return (
    <div role={isOnlyElement ? 'main' : undefined} className={`hcr-theme-${localTheme}`}>
      <Head />
      <div
        aria-hidden={isPanelOpened}
        className={cn(styles.searchMask, {
          [styles.frontpage]: localVariant === 'frontpage',
          [styles.compact]: localVariant === 'compact',
          [styles.fullWidth]: localVariant === 'fullWidth',
          [styles.pageDarkened]: Boolean(pageDarkened),
        })}
      >
        <div className={styles.searchMaskWrapper}>
          <div className={styles.tripModeWrapper}>
            <TripModePicker />
          </div>

          <div className={styles.tripLocationWrapper}>
            <TripLocationWrapper variant={variant} />
          </div>

          <div className={styles.dateRangeWrapper}>
            <DateRangeWrapper />
          </div>

          <div className={styles.tripProductWrapper}>
            <TripProductPicker />
          </div>

          {!isAutoUpdate && (
            <div className={styles.searchButtonWrapper}>
              <SearchButton />
            </div>
          )}
        </div>
      </div>
      <TripLocationMobilePanel />
      <ProductMobilePanel />
    </div>
  );
};
