import { connect } from 'react-redux';
import { optionsSelector } from 'app/store/selectors';
import { updateOptions } from 'app/store/actions';
import { StoreState } from 'app/models/types';
import { Dispatch } from '@reduxjs/toolkit';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';

const mapStateToProps = (state: StoreState) => ({
  options: optionsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOptions: (options: SearchMaskMountOptions) => dispatch(updateOptions(options)),
});

export const WithOptions = connect(mapStateToProps, mapDispatchToProps);
