import { TripModePicker as TripModePickerView } from 'app/components/ui/TripModePicker';
import { connect } from 'react-redux';
import { returnDateVisibleSelector } from 'app/store/selectors';
import { hideReturnDate, requestSearch, showReturnDate } from 'app/store/actions';
import { emitFieldChangedEvent } from 'app/events';
import { FIELD_TRIP_MODE } from 'app/storage/fieldNames';
import { StoreState } from 'app/models/types';
import { Dispatch } from '@reduxjs/toolkit';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { WithOptions } from '../WithOptions';

const mapStateToProps = (state: StoreState) => ({
  isRoundTrip: returnDateVisibleSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: { options: SearchMaskMountOptions }) => ({
  onClickOneWayToggle: () => {
    emitFieldChangedEvent(FIELD_TRIP_MODE, 'oneway');
    dispatch(hideReturnDate());

    if (ownProps.options.autoUpdate) {
      dispatch(requestSearch());
    }
  },
  onClickRoundTripToggle: () => {
    dispatch(showReturnDate());
    emitFieldChangedEvent(FIELD_TRIP_MODE, 'roundtrip');

    if (ownProps.options.autoUpdate) {
      dispatch(requestSearch());
    }
  },
});

export const TripModePicker = WithOptions(
  connect(mapStateToProps, mapDispatchToProps)(TripModePickerView),
);
