export const shopUrls = {
  at: 'shop.flixbus.at',
  bg: 'shop.flixbus.bg',
  bs: 'shop.flixbus.ba',
  ca: 'shop.flixbus.cat',
  cs: 'shop.flixbus.cz',
  da: 'shop.flixbus.dk',
  de: 'shop.flixbus.de',
  de_CH: 'shop.flixbus.ch',
  en: 'shop.global.flixbus.com',
  en_GB: 'shop.flixbus.co.uk',
  en_US: 'shop.flixbus.com',
  es: 'shop.flixbus.es',
  es_US: 'shop.flixbus.com',
  en_CA: 'shop.flixbus.ca',
  et_EE: 'shop.flixbus.ee',
  fr: 'shop.flixbus.fr',
  fr_CA: 'shop.flixbus.ca',
  fr_BE: 'shop.flixbus.be',
  fr_CH: 'shop.flixbus.ch',
  hr: 'shop.flixbus.hr',
  hu: 'shop.flixbus.hu',
  it: 'shop.flixbus.it',
  it_CH: 'shop.flixbus.ch',
  lt_LT: 'shop.flixbus.lt',
  lv_LV: 'shop.flixbus.lt',
  mk: 'shop.flixbus.mk',
  nb: 'shop.flixbus.no',
  no: 'shop.flixbus.no',
  nl: 'shop.flixbus.nl',
  nl_BE: 'shop.flixbus.be',
  pl: 'shop.flixbus.pl',
  pt: 'shop.flixbus.pt',
  pt_BR: 'shop.flixbus.com.br',
  ro: 'shop.flixbus.ro',
  ru: 'shop.flixbus.ru',
  sk: 'shop.flixbus.sk',
  sl: 'shop.flixbus.si',
  sq: 'shop.flixbus.al',
  sr_Latn_RS: 'shop.flixbus.rs',
  sr: 'shop.flixbus.rs',
  rs: 'shop.flixbus.rs',
  sv: 'shop.flixbus.se',
  tr: 'shop.global.flixbus.com',
  uk: 'shop.flixbus.ua',
  zh_US: 'shop.flixbus.com',
};
