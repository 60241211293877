import React, { FC } from 'react';

import styles from '../DebugSwitch/DebugSwitch.scss';

type Option = { value: string; label: any };

type DebugDropdownProps = {
  selected: Option;
  options: Option[];
  onChange: (value: Option) => void;
};

export const DebugDropdown: FC<DebugDropdownProps> = ({ selected, options, onChange }) => (
  <select
    defaultValue={selected.value}
    className={styles.debugSwitchButton}
    onChange={({ target: { value } }) => {
      const option = options.find((x) => x.value === value);
      if (option) {
        onChange(option);
      }
    }}
  >
    {options.map((x) => (
      <option key={x.value} value={x.value}>
        {x.label}
      </option>
    ))}
  </select>
);
