import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {
  requestSearchWatcher,
  setReturnDateWatcher,
  onUpdateOptions,
  onRemoveBikesFromProduct,
  onUpdateLocations,
  onUpdateDates,
  onRetrieveRecentCities,
  onLoadPopularCities,
  onLoadArrivalPopularCities,
  onStoreRecentCity,
  onRemoveRecentCity,
  onSetLocation,
  onSwitchLocations,
  onRequestUpdateLocation,
  onRequestAutocomplete,
  onSetDepartureDate,
  onSetArrivalDate,
  onHideReturnDate,
  onSetProductQuantity,
  onUpdateSearchParams,
} from 'app/store/sagas';
import { features as defaultFeatures } from 'app/config/features';
import { featureToggler } from 'app/functions/abTests/featureToggler';
import { variants } from 'app/options';
import rootReducer from './reducers';
import { isDevelopment } from 'app/functions';
import { isIsolated } from 'app/functions/environment/isIsolated';
import { StoreState } from 'app/models/types';
import { SET_CMS_API_INSTANCE } from './actions';

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [SET_CMS_API_INSTANCE],
      ignoredPaths: ['general.cmsApiInstance'],
    },
  }).concat(sagaMiddleware);

const sagas = [
  onRequestAutocomplete,
  onUpdateOptions,
  requestSearchWatcher,
  setReturnDateWatcher,
  onRemoveBikesFromProduct,
  onUpdateLocations,
  onUpdateDates,
  onRetrieveRecentCities,
  onLoadPopularCities,
  onLoadArrivalPopularCities,
  onStoreRecentCity,
  onRemoveRecentCity,
  onSetLocation,
  onSwitchLocations,
  onSetDepartureDate,
  onSetArrivalDate,
  onSetProductQuantity,
  onRequestUpdateLocation,
  onHideReturnDate,
  onUpdateSearchParams,
];

let unsubscribe: unknown;

export const getStore = (initialState: Partial<StoreState>) => {
  if (typeof unsubscribe === 'function') {
    unsubscribe();
  }

  const initialFeatures = initialState.general?.options.features || {};

  const defaultStore = {
    ...initialState,
    general: {
      ...initialState.general,
      features: {
        ...defaultFeatures,
        ...initialFeatures,
      },
    },
  };

  const store = configureStore({
    reducer: rootReducer,
    // @ts-ignore
    preloadedState: defaultStore,
    middleware,
    devTools: isDevelopment(),
  });

  featureToggler(store.dispatch);

  // register sagas
  sagas.map((saga) => sagaMiddleware.run(saga));

  // Add some options to the body when the options change.
  if (isIsolated) {
    unsubscribe = store.subscribe(() => {
      const { general } = store.getState();
      const { options } = general;
      const { variant } = options;

      variants.map((v) => `variant-${v.value}`).forEach((v) => document.body.classList.remove(v));

      document.body.classList.add(`variant-${variant}`);
    });
  }

  return store;
};
