export const SET_DEPARTURE_DATE = 'SET_DEPARTURE_DATE';
export type SetDepartureDateAction = {
  type: typeof SET_DEPARTURE_DATE;
  date: number;
};
export const setDepartureDate = (date: number) => ({
  type: SET_DEPARTURE_DATE,
  date,
});

export const SET_RETURN_DATE = 'SET_RETURN_DATE';
export type SetReturnDateAction = {
  type: typeof SET_RETURN_DATE;
  date: number;
};
export const setReturnDate = (date: number) => ({
  type: SET_RETURN_DATE,
  date,
});

export const SHOW_RETURN_DATE = 'SHOW_RETURN_DATE';
export type ShowReturnDateAction = {
  type: typeof SHOW_RETURN_DATE;
};
export const showReturnDate = () => ({
  type: SHOW_RETURN_DATE,
});

export const HIDE_RETURN_DATE = 'HIDE_RETURN_DATE';
export type HideReturnDateAction = {
  type: typeof HIDE_RETURN_DATE;
};
export const hideReturnDate = () => ({
  type: HIDE_RETURN_DATE,
});

export const UPDATE_DATES = 'UPDATE_DATES';
export type UpdateDatesAction = {
  type: typeof UPDATE_DATES;
  newDateUTC: string;
  tripIndex: number;
};
export const updateDates = (tripIndex: number, newDateUTC: string) => ({
  type: UPDATE_DATES,
  newDateUTC,
  tripIndex,
});

export type DateActions =
  | SetDepartureDateAction
  | SetReturnDateAction
  | ShowReturnDateAction
  | HideReturnDateAction
  | UpdateDatesAction;
