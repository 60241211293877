export const KEY_NAMES = {
  ENTER: 'Enter',
  TAB: 'Tab',
  UP: 'Up',
  DOWN: 'Down',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ESCAPE: 'Escape',
  SPACE: ' ',
};
