export const countryCodesPerLanguage = {
  bg: 'BG', // Bulgarian
  bs: 'BA', // Bosnian
  ca: 'ES', // Catalan
  cs: 'CZ', // Czech
  da: 'DK', // Danish
  de: 'DE', // German
  'de-at': 'AT', // German (Austria)
  'de-ch': 'CH', // German (Switzerland)
  el: 'GR', // Greek
  'en-ca': 'CA', // English (Canada)
  'en-gb': 'GB', // English (United Kingdom)
  'en-gl': null, // English (Global)
  'en-ie': 'IE', // English (Ireland)
  'en-in': 'IN', // English (India)
  'en-us': 'US', // English (United States)
  es: 'ES', // Spanish
  'es-cl': 'CL', // Spanish (Chile)
  'es-mx': 'MX', // Spanish (Mexico)
  'es-us': 'US', // Spanish (United States)
  et: 'EE', // Estonian
  fi: 'FI', // Finnish
  fr: 'FR', // French
  'fr-be': 'BE', // French (Belgium)
  'fr-ca': 'CA', // French (Canada)
  'fr-ch': 'CH', // French (Switzerland)
  hr: 'HR', // Croatian
  hu: 'HU', // Hungarian
  it: 'IT', // Italian
  'it-ch': 'CH', // Italian (Switzerland)
  lt: 'LT', // Lithuanian
  lv: 'LV', // Latvian
  mk: 'MK', // Macedonian
  nb: 'NO', // Norwegian
  nl: 'NL', // Dutch
  'nl-be': 'BE', // Dutch (Belgium)
  pl: 'PL', // Polish
  'pt-br': 'BR', // Portuguese (Brazil)
  'pt-pt': 'PT', // Portuguese (Portugal)
  ro: 'RO', // Romanian
  ru: '', // -
  sk: 'SK', // Slovak
  sl: 'SI', // Slovenian
  sq: 'AL', // Albanian
  sr: 'RS', // Serbian
  sv: 'SE', // Swedish
  tr: 'TR', // Turkish
  uk: 'UA', // Ukrainian
  'zh-us': 'US', // Chinese (United States)
};
