import { SetReturnDateAction, SET_RETURN_DATE, showReturnDate } from 'app/store/actions';
import { takeEvery, put } from 'redux-saga/effects';

export function* handleOneWayToggleFeature({ date }: { date: number }) {
  if (date) {
    yield put(showReturnDate());
  }
}

export function* setReturnDateWatcher() {
  yield takeEvery<SetReturnDateAction>(SET_RETURN_DATE, handleOneWayToggleFeature);
}
