import { getDataDogInstance } from 'app/functions';
import {
  allProductsSelector,
  arrivalCitySelector,
  departureCitySelector,
  departureDateSelector,
  featuresSelector,
  returnDateSelector,
} from 'app/store/selectors';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useDataDog = () => {
  const [flag, instance] = getDataDogInstance();
  const departureCity = useSelector(departureCitySelector);
  const arrivalCity = useSelector(arrivalCitySelector);
  const departureDate = useSelector(departureDateSelector);
  const returnDate = useSelector(returnDateSelector);
  const features = useSelector(featuresSelector);
  const products = useSelector(allProductsSelector);
  const version = process.env.APP_VERSION;
  useEffect(() => {
    if (flag === 'not-available') {
      console.warn('Datadog agent not available');
    }
    instance.setGlobalContextProperty('searchMask', {
      version,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, version]);
  return useMemo(() => {
    const ctx = {
      version,
      state: {
        departureCity,
        arrivalCity,
        departureDate,
        returnDate,
        features,
        products,
      },
    };
    return {
      addAction: (action: string, extraContext?: Record<string, unknown>) => {
        instance.addAction(`search_mask.${action}`, {
          searchMask: {
            ctx,
            ...(extraContext ? extraContext : {}),
          },
        });
      },
      addError: (action: string | Error, extraContext?: Record<string, unknown>) => {
        instance.addError(action, {
          searchMask: {
            ctx,
            ...(extraContext ? extraContext : {}),
          },
        });
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalCity, departureCity, departureDate, features, flag, returnDate, version, products]);
};
