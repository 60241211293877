export const a11yLabelsKeys = {
  locationInputs: {
    departure: 'search_mask.voice.input_departure_city',
    departureFilled: 'search_mask.voice.input_departure_city_filled',
    arrival: 'search_mask.voice.input_arrival_city',
    arrivalFilled: 'search_mask.voice.input_arrival_city_filled',
    locationSwitcher: 'search_mask.voice.swap_departure_and_arrival',
    departureDialog: 'search_mask.voice.input_departure_city_filled',
    arrivalDialog: 'search_mask.voice.input_arrival_city_filled',
    groupResultsAmount: 'search_mask.voice.input_location_group_amount',
  },
  dates: {
    departure: 'search_mask.voice.select_departure_date',
    arrival: 'search_mask.voice.select_arrival_date',
    departureFilled: 'search_mask.voice.select_departure_date_filled',
    arrivalFilled: 'search_mask.voice.select_arrival_date_filled',
    leftArrow: 'search_mask.voice.previous_month',
    rightArrow: 'search_mask.voice.next_month',
    deleteArrival: 'search_mask.voice.remove_return_date',
    departureDialog: 'search_mask.voice.select_departure_date',
    arrivalDialog: 'search_mask.voice.select_arrival_date',
    nextDay: 'search_mask.voice.next_day',
    prevDay: 'search_mask.voice.prev_day',
  },
  products: {
    input: 'search_mask.voice.trip_for_products',
    productPlus: 'search_mask.label.add',
    productMinus: 'search_mask.label.remove',
    showMoreDiscounts: 'search_mask.voice.show_more_discounts',
    backToProductSelection: 'search_mask.voice.back_to_product_selection',
  },
  searchButton: 'search_mask.voice.search_trips',
  confirmSelection: 'search_mask.voice.confirm_selection',
  close: 'search_mask.voice.close',
  trainStation: 'search_mask.voice.trainStation',
};
