import { createSelector } from '@reduxjs/toolkit';
import {
  departureCountryCodeSelector,
  arrivalCountryCodeSelector,
  departureSubdivisionCodeSelector,
  arrivalSubdivisionCodeSelector,
} from 'app/store/selectors';
import { userTypeSelector } from 'app/store/selectors/user';
import { featureUsVeteransPromotedSelector } from 'app/store/selectors/general';
import {
  getCountryPassengersByCountryCodes,
  getGroupsTripsAvailability,
  getMaxTotalPassengersCountByCountryCode,
} from 'app/functions/product';
import { StoreState } from 'app/models/types';
import { StoreProductField } from 'app/models/types/store/product';
import { UserType, ProductsId, getPassengerCount, ProductsAmount } from '@webc/meeseeks-ui-sdk';

// this includes products that are not active in the current Country-Country pair
export const allProductTypesSelector = (state: StoreState) => state.product.types;
export const relationProductsSelector = (state: StoreState) => state.product.relationProducts;

type CurrentProductTypesSelectorType = [
  StoreProductField['types'],
  string | undefined,
  string | undefined,
  string | null,
  string | null,
  UserType,
  any,
];

type ProductRulesByCountryCodeSelectorType = [string | undefined, string | undefined, UserType];

export const currentProductTypesSelector = createSelector(
  allProductTypesSelector,
  departureCountryCodeSelector,
  arrivalCountryCodeSelector,
  departureSubdivisionCodeSelector,
  arrivalSubdivisionCodeSelector,
  userTypeSelector,
  featureUsVeteransPromotedSelector,
  (...args: CurrentProductTypesSelectorType) => getCountryPassengersByCountryCodes(...args),
);

export const currentProductTypesExtendedSelector = createSelector(
  allProductTypesSelector,
  departureCountryCodeSelector,
  arrivalCountryCodeSelector,
  departureSubdivisionCodeSelector,
  arrivalSubdivisionCodeSelector,
  userTypeSelector,
  featureUsVeteransPromotedSelector,
  (...args: CurrentProductTypesSelectorType) =>
    getCountryPassengersByCountryCodes(...args, { extended: true }),
);

export const maxTotalPassengersCountSelector = createSelector(
  departureCountryCodeSelector,
  arrivalCountryCodeSelector,
  userTypeSelector,
  (...args: ProductRulesByCountryCodeSelectorType) =>
    getMaxTotalPassengersCountByCountryCode(...args),
);

export const showExtendedProductsInfoSelector = createSelector(
  departureCountryCodeSelector,
  arrivalCountryCodeSelector,
  (a, b) => a === 'PL' && b === 'PL',
);

export const allProductsSelector = createSelector(
  currentProductTypesSelector,
  currentProductTypesExtendedSelector,
  (products, extendedProducts) => ({ ...products, ...extendedProducts }),
);

export const selectedProductsSelector = createSelector(relationProductsSelector, (products) => {
  const selectedProducts = Object.values(products).reduce<Partial<StoreProductField['types']>>(
    (acc, curr) => {
      if (curr && curr.selectedAmount >= 1) {
        acc[curr.id] = curr;
      }
      return acc;
    },
    {},
  );

  if (!Object.keys(selectedProducts).length) {
    selectedProducts['adult'] = products['adult'];
  }

  return selectedProducts;
});

export const selectedProductsAmountSelector = createSelector(
  relationProductsSelector,
  (products) => {
    const selectedProducts = Object.values(products).reduce<ProductsAmount>((acc, curr) => {
      if (curr && curr.selectedAmount >= 1) {
        acc[curr.id] = curr.selectedAmount;
      }
      return acc;
    }, {});

    if (!Object.keys(selectedProducts).length) {
      selectedProducts['adult'] = products['adult']?.selectedAmount;
    }

    return selectedProducts;
  },
);

export const passengerCountSelector = createSelector(
  selectedProductsAmountSelector,
  getPassengerCount,
);

export const otherPassengerProductsSelector = (selectedProductType: ProductsId) =>
  createSelector(selectedProductsSelector, (products) => {
    const {
      [selectedProductType]: _value,
      bike_slot: _bike_slot_value,
      wheelchair: _wheelchair_value,
      ...rest
    } = products;
    return rest;
  });

export const wheelchairSelectedSelector = createSelector(
  selectedProductsSelector,
  (products) => products?.wheelchair?.selectedAmount,
);

export const groupsTripsAvailabilitySelector = createSelector(
  departureCountryCodeSelector,
  arrivalCountryCodeSelector,
  userTypeSelector,
  (...args: ProductRulesByCountryCodeSelectorType) => getGroupsTripsAvailability(...args),
);
