import React, { useEffect, useState } from 'react';
import {
  departureDateSelector,
  departureCitySelector,
  arrivalCitySelector,
  dateLabelSelector,
} from 'app/store/selectors';
import { useSelector } from 'react-redux';
import { useTranslations } from 'app/hooks/useTranslations';

export const Head: React.FC = () => {
  // Self flag if title is in control of this component
  const [hasTitleControl, setHasTitleControl] = useState(false);
  const dateLabel = useSelector(dateLabelSelector);
  const departureDate = useSelector(departureDateSelector);
  const departureCity = useSelector(departureCitySelector);
  const arrivalCity = useSelector(arrivalCitySelector);

  const { getPageTitle } = useTranslations({ getPageTitle: 'search_result_route_title' });

  useEffect(() => {
    if (departureCity && arrivalCity && !!getPageTitle && departureDate) {
      const date = dateLabel(departureDate);
      const title = getPageTitle({
        departure: departureCity.name,
        arrival: arrivalCity.name,
        date,
      });
      const titleTags = document.getElementsByTagName('title');
      if (
        !titleTags[0] ||
        titleTags[0].innerHTML.replace(/[ \n]/g, '').length === 0 ||
        hasTitleControl
      ) {
        document.title = title;
        // flag that it's this component that changed the title
        // this ensures title to be updated when data changes
        if (!hasTitleControl) setHasTitleControl(true);
      }
    }
  }, [departureCity, arrivalCity, getPageTitle, departureDate]);
  return null;
};
