import { call, select, takeEvery } from 'redux-saga/effects';
import { SWITCH_LOCATIONS, SwitchLocationsAction } from 'app/store/actions';
import {
  cmsApiOnDepartureChangeSelector,
  cmsApiOnArrivalChangeSelector,
  departureCitySelector,
  arrivalCitySelector,
} from 'app/store/selectors';

export function* onSwitchLocationsWorker() {
  const onDepartureCityChanged: ReturnType<typeof cmsApiOnDepartureChangeSelector> = yield select(
    cmsApiOnDepartureChangeSelector,
  );
  const onArrivalCityChanged: ReturnType<typeof cmsApiOnArrivalChangeSelector> = yield select(
    cmsApiOnArrivalChangeSelector,
  );
  const departureLocation: ReturnType<typeof departureCitySelector> = yield select(
    departureCitySelector,
  );
  const arrivalLocation: ReturnType<typeof arrivalCitySelector> = yield select(arrivalCitySelector);

  if (departureLocation.name && departureLocation.uuid && departureLocation.legacyId) {
    const departurePayload = {
      uuid: departureLocation.uuid,
      legacyId: departureLocation.legacyId,
      cityName: departureLocation.name,
    };
    yield call(onDepartureCityChanged, departurePayload);
  }

  if (arrivalLocation.name && arrivalLocation.uuid && arrivalLocation.legacyId) {
    const arrivalPayload = {
      uuid: arrivalLocation.uuid,
      legacyId: arrivalLocation.legacyId,
      cityName: arrivalLocation.name,
    };

    yield call(onArrivalCityChanged, arrivalPayload);
  }
}

export function* onSwitchLocations() {
  yield takeEvery<SwitchLocationsAction>(SWITCH_LOCATIONS, onSwitchLocationsWorker);
}
