import { takeEvery, select, put } from 'redux-saga/effects';
import {
  LOAD_POPULAR_CITIES,
  SET_LOCATION,
  setPopularCities,
  SetLocationAction,
  LoadPopularCitiesAction,
} from 'app/store/actions';
import { optionsSelector, departureCitySelector } from 'app/store/selectors';
import { AutocompleteResult } from 'app/models/types/store/location';
import { fetchPopularCities } from 'app/providers';
import { CityDetailsResult } from 'app/models/types/store/location';

export function* onLoadPopularCitiesWorker({ direction }: LoadPopularCitiesAction) {
  let popularCities: AutocompleteResult[] = [];
  const departure: CityDetailsResult = yield select(departureCitySelector);
  const { locale, citiesDetailsEndpoint, recommendationUrl } = yield select(optionsSelector);

  try {
    popularCities = yield fetchPopularCities(
      recommendationUrl,
      citiesDetailsEndpoint,
      locale,
      direction === 'to' ? departure.uuid : undefined,
    );
  } catch (e) {
    popularCities = [];
  }

  yield put(setPopularCities(popularCities, direction));
}

export function* onLoadPopularCities() {
  yield takeEvery<LoadPopularCitiesAction>(LOAD_POPULAR_CITIES, onLoadPopularCitiesWorker);
}

export function* onLoadArrivalPopularCities() {
  yield takeEvery<SetLocationAction>(
    SET_LOCATION,
    ({ direction }) =>
      direction === 'from' &&
      onLoadPopularCitiesWorker({ direction: 'to', type: LOAD_POPULAR_CITIES }),
  );
}
