import { ProductsId, SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import {
  showStationSuggestionSelector,
  optionsSelector,
  selectedProductsSelector,
} from 'app/store/selectors';
import { call, put, select } from 'redux-saga/effects';
import { fetchCityRelationDetails } from 'app/providers';
import { mapCityDetailResponse } from 'app/functions';
import { isUuid } from 'app/functions/general/isUuid';
import {
  hideReturnDate,
  requestUpdateLocation,
  setDepartureDate,
  setProductQuantity,
  setReturnDate,
} from 'app/store/actions';
import { ProductDataType } from 'app/models/types/store/product';
import { uniq } from 'lodash';

type UpdateLocationDataType = {
  options: SearchMaskMountOptions;
  fetchCitiesDetails: boolean;
  cityId: number | string;
  stationId?: number | string;
  stationName?: string;
  direction: 'from' | 'to';
};
export function* updateLocationData({
  options,
  fetchCitiesDetails,
  cityId,
  direction,
  stationId,
  stationName,
}: UpdateLocationDataType) {
  if (cityId) {
    const showStationSuggestions: boolean = yield select(showStationSuggestionSelector);
    let newLocation;
    if (fetchCitiesDetails) {
      const cityDetailsResponse: Awaited<ReturnType<typeof fetchCityRelationDetails>> = yield call<
        typeof fetchCityRelationDetails
      >(fetchCityRelationDetails, options.citiesDetailsEndpoint, cityId, options.locale);
      if ('code' in cityDetailsResponse) {
        console.error(`Can't retrieve city for location`, location);
        return;
      }
      const [result] = cityDetailsResponse.map((c) => mapCityDetailResponse(c));
      newLocation = result;
    } else {
      const key = isUuid(cityId) ? 'uuid' : 'legacyId';
      newLocation = {
        [key]: cityId,
      };
    }
    yield put(
      requestUpdateLocation(direction, {
        ...newLocation,
        ...(showStationSuggestions
          ? {
              stationId,
              stationName,
            }
          : null),
      } as { uuid: string } | { stationId: string } | { legacyId: number }),
    );
  }
}
export function* updateSearchMaskValues(
  searchMaskValues: SearchMaskMountOptions['initialSearchMaskValues'],
  isInit: boolean,
  oldOptions: ReturnType<typeof optionsSelector>,
) {
  const departureId = searchMaskValues.departureCity;
  const arrivalId = searchMaskValues.arrivalCity;

  yield call(updateLocationData, {
    options: oldOptions,
    fetchCitiesDetails: !isInit,
    cityId: departureId,
    direction: 'from',
    stationId: searchMaskValues?.departureStop,
    stationName: searchMaskValues?.departureStopName,
  });

  yield call(updateLocationData, {
    options: oldOptions,
    fetchCitiesDetails: !isInit,
    cityId: arrivalId,
    direction: 'to',
    stationId: searchMaskValues?.arrivalStop,
    stationName: searchMaskValues?.arrivalStopName,
  });

  if (searchMaskValues.departureDate) {
    yield put(setDepartureDate(Number(searchMaskValues.departureDate)));
  }
  if (searchMaskValues.returnDate) {
    yield put(setReturnDate(Number(searchMaskValues.returnDate)));
  } else {
    yield put(hideReturnDate());
  }
  if (searchMaskValues.products) {
    const products = Object.keys(searchMaskValues.products) as ProductsId[];
    const selectedProducts: { [key in ProductsId]?: ProductDataType } =
      yield select(selectedProductsSelector);
    const selectedProductsIds: ProductsId[] = uniq([
      ...(Object.keys(selectedProducts) as ProductsId[]),
      ...products,
    ]);
    // If there's no adult in product, then set adult product quantity to 0 to overide default quantity set by productType in SDK.
    if (!selectedProductsIds.includes('adult')) {
      yield put(setProductQuantity('adult', 0));
    }

    for (let i = 0; i < selectedProductsIds.length; i++) {
      const amount = searchMaskValues.products[selectedProductsIds[i]] || 0;
      if (typeof amount !== 'undefined') {
        yield put(setProductQuantity(selectedProductsIds[i], amount));
      }
    }
  }
}
