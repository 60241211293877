import { useEffect, useRef, useState } from 'react';
import {
  AutocompleteGroupType,
  AutocompleteOptionType,
} from '@flixbus/honeycomb-react/src/components/autocomplete/autocomplete-options/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  arrivalCitySelector,
  autoUpdateSelector,
  departureCitySelector,
  showStationSuggestionSelector,
  selectedLocationsSelector,
} from 'app/store/selectors';
import { requestSearch, requestUpdateLocation } from 'app/store/actions';
import { mapAutocompleteToLocation, redirectToUrl } from 'app/functions';
import { emitFieldChangedEvent, emitFieldClosedEvent, emitSelectCityEvent } from 'app/events';
import { AutocompleteResult, AutocompleteResultStation } from 'app/models/types/store/location';
import { FIELD_ARRIVAL_CITY, FIELD_DEPARTURE_CITY } from 'app/storage/fieldNames';
import { ResultsType } from 'app/hooks/useResults';
import { emitSelectStationEvent } from 'app/events';
import { useTranslations } from 'app/hooks/useTranslations';
import { emitSearchMaskMapEvent } from 'app/events/emitSearchMaskMapEvent';
import { RemoveButton } from '../Autocomplete/RemoveButton';
import React from 'react';
import { useOutsideClick } from 'app/hooks/useClickOutside';

type AutocompleteProps = {
  direction: 'to' | 'from';
  isAlwaysExpanded: boolean;
  isFocused: boolean;
  setIsFocused: (value: boolean) => void;
  setValue: (value: string) => void;
  options: AutocompleteGroupType[];
  citiesById: Record<
    string,
    AutocompleteResult & { positionOnList: number; station: AutocompleteResultStation }
  >;
  afterSelectAction?: () => void;
  value: string;
  resultsType: ResultsType;
};

type selectedCityType = AutocompleteResult & {
  positionOnList: number;
  stationId?: string;
  stationName?: string;
};
export const useAutocomplete = ({
  direction,
  isAlwaysExpanded,
  isFocused,
  setIsFocused,
  setValue,
  options,
  citiesById,
  afterSelectAction,
  value,
  resultsType,
}: AutocompleteProps) => {
  const [data, setData] = useState<AutocompleteGroupType[]>([]);
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const selected = useSelector(selectedLocationsSelector)[direction];
  const areStationSuggestionsAvailable = useSelector(showStationSuggestionSelector);
  const isAutoUpdate = useSelector(autoUpdateSelector);
  const { getMapLink } = useTranslations({ getMapLink: 'search_mask.explore_map.link_href' });
  const departureCityId = useSelector(selectedLocationsSelector).from.uuid || '';

  const departureCity = useSelector(departureCitySelector);
  const arrivalCity = useSelector(arrivalCitySelector);

  const onHide = () => {
    if (isAlwaysExpanded) return null;
    setValue(selected.stationName || selected.name || '');
    setIsFocused(false);
    setData([]);
  };

  useOutsideClick(wrapperRef, () => {
    onHide();
  });

  useEffect(() => {
    if (isFocused || isAlwaysExpanded) {
      return setData(options);
    }
  }, [options, isFocused, isAlwaysExpanded]);

  const onSelect = (city: AutocompleteOptionType) => {
    const params = departureCityId && direction === 'to' ? `?departureCity=${departureCityId}` : '';
    if (city.key === 'mapItem') {
      const href = `${getMapLink() || 'https://global.flixbus.com/bus-routes'}${params}`;
      emitSearchMaskMapEvent();
      return redirectToUrl(href);
    }
    if (city.key) {
      const cityData = citiesById[city.key];
      const selectedCity: selectedCityType =
        cityData.isStation && cityData.cityId
          ? {
              ...citiesById[cityData.cityId],
              stationId: city.key,
              stationName: city.title,
            }
          : cityData;
      dispatch(requestUpdateLocation(direction, mapAutocompleteToLocation(selectedCity)));
      emitFieldChangedEvent(
        direction === 'from' ? 'location.departure' : 'location.arrival',
        city.title,
      );
      setData([]);
      setValue(city.title);

      if (areStationSuggestionsAvailable) {
        emitSelectStationEvent({
          isStationClicked: !!cityData.isStation,
          numberOfAvailableStationsInCity: selectedCity.stations?.length || 0,
          isTrainStation: cityData.station?.is_train,
        });
      }

      emitSelectCityEvent({
        direction,
        isFlixbusCity: selectedCity.isFlixbusCity,
        selectedCityId: selectedCity.id,
        selectedCityName: selectedCity.name,
        country: selectedCity.country,
        searchedTerm: value,
        positionFromTop: cityData.positionOnList,
        isStation: !!cityData.isStation,
        isPopular: resultsType === 'popular',
        isRecent: resultsType === 'recent',
        selectedStationName: selectedCity.stationName,
      });

      if (afterSelectAction) {
        afterSelectAction();
      }
      emitFieldClosedEvent(direction === 'from' ? FIELD_DEPARTURE_CITY : FIELD_ARRIVAL_CITY);
      setIsFocused(false);

      if (isAutoUpdate) {
        const isNewCity =
          (direction === 'from' && departureCity.uuid !== selectedCity.id) ||
          (direction === 'to' && arrivalCity.uuid !== selectedCity.id);

        const isNewStation =
          (direction === 'from' && departureCity.stationId !== selectedCity.stationId) ||
          (direction === 'to' && arrivalCity.stationId !== selectedCity.stationId);

        const isNew = isNewCity || isNewStation;

        if (isNew) {
          dispatch(requestSearch(undefined, isNew));
        }
      }
    }
  };

  if (resultsType === 'recent' && data[0]?.options) {
    data[0].options = data[0].options.map((x) => {
      const cityData = x.key ? citiesById[x.key] : undefined;
      return {
        ...x,
        widget: (
          <RemoveButton
            cityName={cityData?.name || ''}
            cityId={cityData?.id || ''}
            direction={direction}
          />
        ),
      };
    });
  }

  return {
    data,
    selected,
    onSelect,
    wrapperRef,
  };
};
