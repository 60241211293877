import { productsConfig, ProductsId, UserType, extendedProductRules } from '@webc/meeseeks-ui-sdk';
import { StoreProductField } from 'app/models/types/store/product';

const defaultKey = 'Any-Any';

function isProductIds(arg: ProductsId[] | undefined): arg is ProductsId[] {
  return arg !== undefined;
}

const getPassengerRules = (
  relation: string,
  userType: UserType,
  extended: boolean,
  usVeteransPromoted: boolean,
) => {
  if (extended) {
    return productsConfig.countryPassengerRulesExtended;
  }
  const userSpecificConfig = productsConfig.countryPassengerRulesByRole?.[relation]?.[userType];
  if (typeof userSpecificConfig === 'object') {
    return { [relation]: userSpecificConfig };
  }

  if (usVeteransPromoted && relation === 'US-US' && userType == 'customer') {
    return {
      ...productsConfig.countryPassengerRules,
      [relation]: ['adult', 'us_children_2_15', 'us_veteran', 'bike_slot', 'wheelchair'],
    };
  }
  return productsConfig.countryPassengerRules;
};

/**
 * products rules differ from country to country and this function make sure to apply correct rules
 * it does so by retrieving the overall product config and extracting the pax types that match countries
 * of current search.
 * Rules are always referenced as "DE-DE" where "Any-Any" is the default rule for country which do NOT have specific rules
 *
 * Further more this function applies any custom rule which only exists for search.
 * This rules are described in extendedProductRules
 *
 */
export const getCountryPassengersByCountryCodes = (
  allProducts: StoreProductField['types'],
  departureCountryCode: string | undefined,
  arrivalCountryCode: string | undefined,
  departureSubdivisionCode: string | null,
  arrivalSubdivisionCode: string | null,
  userType: UserType,
  usVeteransPromoted: boolean,
  options = { extended: false },
): Partial<StoreProductField['types']> => {
  const countryId = `${departureCountryCode}-${arrivalCountryCode}`;
  const subdivisionId = `${departureSubdivisionCode?.replace(
    '-',
    '_',
  )}-${arrivalSubdivisionCode?.replace('-', '_')}`;
  const passengerRules = getPassengerRules(
    countryId,
    userType,
    options.extended,
    usVeteransPromoted,
  );
  const countryRule = passengerRules[countryId];
  const subdivisionRule = passengerRules[subdivisionId];
  const extendedRule = extendedProductRules[countryId] && extendedProductRules[countryId][userType];
  const countryRuleProducts = isProductIds(countryRule) ? countryRule : passengerRules[defaultKey];
  const subdivisionRuleProducts = isProductIds(subdivisionRule) ? subdivisionRule : [];

  if (!countryRuleProducts && !subdivisionRuleProducts.length) return {};
  const rule = [...subdivisionRuleProducts, ...(countryRuleProducts || [])];
  const hideExtendedRule =
    usVeteransPromoted && countryId === 'US-US' && options.extended && userType === 'customer';
  if (hideExtendedRule) {
    return {};
  }

  return rule.reduce(
    (acc, c) => {
      if (!extendedRule) {
        acc[c] = { ...allProducts[c as ProductsId] };
      } else if (extendedRule && c in extendedRule) {
        acc[c] = { ...allProducts[c as ProductsId] };
        acc[c].maxAmount = extendedRule[c]!.maxAmount;
      }
      if (acc[c] && acc[c].selectedAmount >= acc[c].maxAmount) {
        acc[c].selectedAmount = acc[c].maxAmount;
      }
      return acc;
    },
    {} as StoreProductField['types'],
  );
};
