import React, { FC } from 'react';
import { retryCall } from 'app/api';
import { LazyComponent } from 'app/containers/LazyComponent';
import { DatePickerFallback } from '../../ui/DatePickerFallback';

const TripDatePicker = React.lazy(() => retryCall(() => import('app/containers/TripDatePicker')));

export const DateRangeWrapper: FC = () => (
  <LazyComponent fallback={<DatePickerFallback />}>
    <TripDatePicker />
  </LazyComponent>
);
