import { Panel, PanelHeader, PanelContent } from '@flixbus/honeycomb-react';
import { Autocomplete } from 'app/components/tripLocation/Autocomplete/Autocomplete';
import React, { useEffect, useRef } from 'react';
import styles from './TripLocationMobilePanel.scss';
import { useTranslations } from 'app/hooks/useTranslations';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import { useDispatch, useSelector } from 'react-redux';
import { openedOverlaySelector } from 'app/store/selectors';
import { OpenedOverlayType, TripLocationPanelType } from 'app/models/types/store/ui';
import { toggleOverlay } from 'app/store/actions';
import { emitFieldClosedEvent } from 'app/events';
import { FIELD_ARRIVAL_CITY, FIELD_DEPARTURE_CITY } from 'app/storage/fieldNames';
import { tabTrap } from 'app/providers/tabTrap';

export const TripLocationMobilePanel = () => {
  const overlayData: OpenedOverlayType =
    useSelector(openedOverlaySelector) || ({} as TripLocationPanelType);
  const { name, direction } = overlayData as TripLocationPanelType;
  const isActive = name === `TripLocationPanel-${direction}`;
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { getDialogAriaLabelFrom, getDialogAriaLabelTo, getCloseLabel, translationGetLabel } =
    useTranslations({
      translationGetLabel: direction,
      getDialogAriaLabelFrom: a11yLabelsKeys.locationInputs.departureDialog,
      getDialogAriaLabelTo: a11yLabelsKeys.locationInputs.arrivalDialog,
      getCloseLabel: a11yLabelsKeys.close,
    });

  const onClose = () => {
    document.getElementById(`searchInputMobile-${direction}`)?.focus();
    emitFieldClosedEvent(direction === 'from' ? FIELD_DEPARTURE_CITY : FIELD_ARRIVAL_CITY);
  };

  useEffect(() => {
    if (isActive && ref.current !== null) {
      const item: HTMLInputElement | null = ref.current?.querySelector(`#searchInput-${direction}`);
      setTimeout(() => {
        item?.focus();
        item?.click();
      });
    }
  }, [isActive, direction]);

  const tabTrapAction = (event: React.KeyboardEvent) =>
    tabTrap({
      event,
      firstItemSelector: '#close-button',
      lastItemSelector: `#searchInput-${direction}`,
      isActive,
      wrapper: ref.current,
    });

  if (!isActive) return null;

  const onCloseHandler = () => {
    onClose();
    dispatch(toggleOverlay());
  };

  return (
    <div ref={ref}>
      <Panel
        id="mobile-panel"
        active={isActive}
        position="bottom"
        fullSize
        hasOverlay
        onOverlayClick={onCloseHandler}
        extraClasses={styles.panelWrapper}
        disableFocusManagement={true}
        aria-label={direction === 'to' ? getDialogAriaLabelTo() : getDialogAriaLabelFrom()}
      >
        <PanelHeader
          closeButtonProps={{
            label: getCloseLabel(),
            onClick: onCloseHandler,
            onKeyDown: tabTrapAction,
            id: 'close-button',
          }}
          extraClasses={styles.panelHeader}
        >
          {translationGetLabel()}
        </PanelHeader>
        <PanelContent>
          <Autocomplete
            direction={direction}
            afterSelectAction={onCloseHandler}
            tabTrapAction={tabTrapAction}
            optionsListVisible
          />
        </PanelContent>
      </Panel>
    </div>
  );
};
