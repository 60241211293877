import { format } from 'date-fns';
import { queryStringMapping } from 'app/config/queryStringMapping';
import { fromUnixTime } from 'date-fns';
import { ProductsAmount } from '@webc/meeseeks-ui-sdk/build/types/definitions/common/Products';
import { Country } from '@webc/meeseeks-ui-sdk';

export type ParamsSearchPayload = {
  departureCity: string;
  arrivalCity: string;
  routeName: string;
  departureDate: number | null;
  returnDate: number | null;
  products: ProductsAmount;
  locale: string;
  departureCountryCode?: Country;
  arrivalCountryCode?: Country;
  isGlobalTripSearch?: number;
};

export const getUrlParamsFromSearchPayload = (searchPayload: ParamsSearchPayload) => {
  const urlParams = new URLSearchParams();

  Object.keys(searchPayload).forEach((key) => {
    const paramConfig = queryStringMapping[key];
    let parsedValue: string | null = null;

    if (searchPayload[key] === null || searchPayload[key] === undefined) return;

    switch (paramConfig.type) {
      case 'date':
        parsedValue = format(fromUnixTime(searchPayload[key]), paramConfig.format);
        break;

      case 'custom': {
        paramConfig.toUrl(urlParams, searchPayload[key]);
        break;
      }

      case 'boolean': {
        parsedValue = searchPayload[key] ? 'true' : 'false';
        break;
      }

      case 'string':
      case 'int':
      default: {
        parsedValue = searchPayload[key];
      }
    }

    if (parsedValue) {
      urlParams.set(paramConfig.paramName, parsedValue);
    }
  });

  return urlParams;
};
